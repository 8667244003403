import { registerWidget } from '../../../js/core/widget/widget-directory'
import { SearchResultTemplate } from '../../components/search-result/c-search-result.template'
import { LoaderTemplate } from '../../components/loader/c-loader.template'
import { register } from '../../../js/document/namespace'
import { language } from '../../../js/user/locale-settings'
import Component from '../../../js/core/component/component'
import { FILTER_TYPES } from '../../../js/data/filters/config'
import { elementFromString, getData } from '../../../js/document/html-helper'
import registeredEvents from '../../../js/helpers/registered-events'
import { debounce } from '../../../js/utils'
import { searchMap as searchMapEvents } from './event-types'

const widgetApi = 'w-search-map'
const globalLocales = register(`window.sundio.i18n.${language}.global`)
const componentLocales = register(`window.sundio.i18n.${language}.search`)

const EventEmitter = require('eventemitter3')

const maxCountryZoomLevel = 5
const maxRegionZoomLevel = 8

const delayMilliseconds = 100

const widgetQueries = {
  modal: `.${widgetApi}__modal`,
  mapInteractive: 'c-map-interactive',
  mapInteractiveContainer: 'c-map-interactive__container',
  results: `.${widgetApi}__search-results`,
  resultsContainer: `.${widgetApi}__search-results-container`,
  infoWindowContainer: `${widgetApi}__info-window-container`,
  searchModal: 'w-search__modal',
  trackAttr: 'data-track',
  refreshBtn: `${widgetApi}__refresh-btn`,
  resultsBtn: `${widgetApi}__results-btn`,
  filtersBtn: `${widgetApi}__filters-btn`,
  resultsShowingDestinationsClass: `${widgetApi}__search-results__showing-destinations`,
  refreshBtnShowingDestinationsClass: `${widgetApi}__refresh-btn__showing-destinations`,
  resultsHideClass: 'w-search__results-hide',
  filtersHideClass: 'w-search__filters-hide',
  searchResult: `${widgetApi}__search-result`,
  searchResultIdAttr: `data-${widgetApi}___search-result-id`,
  mapFilters: `.${widgetApi}__search-filters`,
  filtersContainer: `.${widgetApi}__search-filters-container`,
  btnGroup: '[data-js-component="c-button-group"]',
  btnGroupApi: 'c-button-group',
  search: '.w-search',
  searchFiltersId: 'w-search__filters',
  selectedFiltersContent: `.${widgetApi}__selected-filters-content`,
  searchSelectedFilters: '.w-search__selected-filters',
  mapLoader: 'w-search-map__loader-container',
  mobileFilterBtn: `.${widgetApi}__mobile-filters-btn`,
  noAccommodationResultsClass: 'w-search-map__search-results-container-no-accommodation-results',
  leftWrapper: `.${widgetApi}__left-wrapper`
}

require('../../components/map-interactive/main')
require('../../components/modal/main')
export default class SearchMap {
  constructor (element, options = {}) {
    this.element = element
    this._getHtmlElements()
    this.mapApi = this.mapInteractive[widgetQueries.mapInteractive]
    this.data = options.data
    this.settings = { ...getData(this.element), ...options }

    this.modal['c-modal'].events.on('opening', this._openingMap, this)

    this.modal['c-modal'].events.on('opened', this._onOpenModal, this)
    this.modal['c-modal'].events.on('closed', this._closeMap, this)

    this.searchModal['c-modal'].events.on('opening', this._openAcmModal, this)
    this.searchModal['c-modal'].events.on('closed', this._closeAcmModal, this)

    this.btnGroupApi = this.btnGroup[widgetQueries.btnGroupApi]

    this.btnGroupApi.events.on('changeActiveElement', el => {
      this._changeActiveTab(el)
    })

    this.events = new EventEmitter()

    this.locals = this._getLocales()

    this.emitChange = () => this.events.emit('change')
    this.onBoundsChangedDebounce = debounce(this.emitChange, 750)

    this.element[widgetApi] = {
      element: this.element,
      events: this.events,
      setMapData: this._setMapData.bind(this),
      getGeoBoundingBox: this._getGeoBoundingBox.bind(this),
      getLimit: this._getLimit.bind(this),
      enableMap: this._enableMap.bind(this),
      isOpen: this._isOpen.bind(this),
      clearMap: this._clearMap.bind(this),
      modalAPI: this.modal['c-modal']
    }

    registeredEvents.registerWidgetEvents(widgetApi, this.events, {
      ...this.element.hasAttribute(widgetQueries.trackAttr) && { track: this.element.attributes[widgetQueries.trackAttr].value }
    })

    this.refreshBtn.addEventListener('click', () => this._refreshBtnClick())

    this.searchFilters['c-side-drawer'].events.on('opened', () => this._openFilters())
    this.searchFilters['c-side-drawer'].events.on('closed', () => this._closeFilters())
    this.closeFiltersBtn.addEventListener('click', () => this._clickCloseFiltersBtn())

    this.resultType = this.settings.useDestinationClustering ? 'Country' : 'Accommodation'
  }

  _getHtmlElements () {
    this.results = this.element.querySelector(widgetQueries.results)
    this.resultsContainer = this.element.querySelector(widgetQueries.resultsContainer)
    this.modal = document.querySelector(widgetQueries.modal)
    this.mapInteractive = this.element.querySelector(`.${widgetQueries.mapInteractive}`)
    this.mapInteractiveContainer = this.element.querySelector(`.${widgetQueries.mapInteractiveContainer}`)
    this.searchModal = document.getElementById(`${widgetQueries.searchModal}`)
    this.refreshBtn = this.element.querySelector(`.${widgetQueries.refreshBtn}`)
    this.resultsBtn = this.element.querySelector(`.${widgetQueries.resultsBtn}`)
    this.filtersBtn = this.element.querySelector(`.${widgetQueries.filtersBtn}`)
    this.mapFilters = this.element.querySelector(`${widgetQueries.mapFilters}`)
    this.btnGroup = this.element.querySelector(`${widgetQueries.btnGroup}`)
    this.filterResultsContainer = this.element.querySelector(`${widgetQueries.filterResultsContainer}`)
    this.searchElement = document.querySelector(widgetQueries.search)
    this.searchFilters = document.getElementById(widgetQueries.searchFiltersId)
    this.searchFiltersParentElement = this.searchFilters.parentElement
    this.mapFiltersContainer = this.element.querySelector(`${widgetQueries.filtersContainer}`)
    this.searchSelectedFilters = document.querySelector(`${widgetQueries.searchSelectedFilters}`)
    this.searchSelectedFiltersParentElement = this.searchSelectedFilters.parentElement
    this.mapLoader = this.element.querySelector(`.${widgetQueries.mapLoader}`)
    this.mobileFilterBtn = this.element.querySelector(`${widgetQueries.mobileFilterBtn}`)
    this.closeFiltersBtn = this.searchFilters.querySelector('.w-search__filters-apply')
    this.leftWrapper = this.element.querySelector(`${widgetQueries.leftWrapper}`)
  }

  _setMapData (data, filtersCollection, selectedValues, geoBoundingFiltered) {
    this.data = data
    this.filtersCollection = filtersCollection
    this.changedData = true
    this._setResultType(selectedValues, geoBoundingFiltered)
    if (this.opened) this._refreshMap(geoBoundingFiltered)
  }

  _openingMap () {
    if (!this.settings.useDestinationClustering) {
      this.resultsBtn.click()
    }
    if (this.searchFilters) {
      this.mapFiltersContainer.appendChild(this.searchFilters)
    }
  }

  _onOpenModal () {
    this._waitForLoadedMapBeforeOpening()
    this._isBodyScrollingLocked(true)
    this.opened = true
  }

  _isBodyScrollingLocked (value) {
    document.documentElement.style.overflow = value ? 'hidden' : 'scroll'
    document.body.scroll = value ? 'no' : 'yes'
  }

  _openMap () {
    if (this.loadOnOpen()) {
      this.events.emit('onRefreshMap', { resultType: 'Reload', markerId: null })
      this.postRefreshMap()
      return
    }

    if (this.data && this.changedData) {
      this._clearMap()
      this._refreshMap(false)
    }
    this.postRefreshMap()
    this.mapApi.zoomToMarkers()
  }

  postRefreshMap () {
    this.btnGroup.style.display = 'flex'
    this.refreshBtn.style.display = 'inline-block'
    this._addEventsOnceToMap()
  }

  loadOnOpen () {
    const mapResultsLessThanMapLimit = this.data.results.length < this.settings.mapLimit
    const aNewCallWouldReturnMoreResults = this.data.results.length < this.data.pagination.totalResults

    return this.settings.loadOnOpen && mapResultsLessThanMapLimit && aNewCallWouldReturnMoreResults && this.changedData && !this.settings.useDestinationClustering
  }

  _waitForLoadedMapBeforeOpening () {
    if (window.google && window.google.maps) {
      this._openMap()
    } else {
      setTimeout(() => {
        this._waitForLoadedMapBeforeOpening()
      }, delayMilliseconds)
    }
  }

  _refreshBtnClick () {
    this.events.emit('onRefreshMap', { resultType: 'Destination', markerId: null })
    this.events.emit(searchMapEvents.SEARCH_MAP_REFRESH)
  }

  _enableMap (enabled) {
    if (!enabled) {
      this._emptyResultsContainer()
    }
    this._enableRefreshBtn(enabled)
    this._addLoaderOnResultsContainer(!enabled)
    this._enableMapComponent(enabled)
    this._enableMobileFilterBtn(enabled)
    this._enableMobileCloseFiltersBtn(enabled)
  }

  _enableMapComponent (enabled) {
    this.mapInteractive[enabled ? 'removeAttribute' : 'setAttribute']('disabled', true)
  }

  _enableRefreshBtn (enabled) {
    this.refreshBtn['c-btn'].setProps({
      disabled: !enabled,
      loading: !enabled
    })
  }

  _enableMobileFilterBtn (enabled) {
    this.mobileFilterBtn['c-btn'].setProps({
      disabled: !enabled
    })
  }

  _enableMobileCloseFiltersBtn (enabled) {
    this.closeFiltersBtn['c-btn'].setProps({
      disabled: !enabled
    })
  }

  _closeMap () {
    this._closeFilters()
    this.searchFilters['c-side-drawer'].close()
    if (this.searchFilters) {
      this.searchFiltersParentElement.appendChild(this.searchFilters)
    }

    this._clearInfoWindow()
    this._clearActiveMarker()
    this._isBodyScrollingLocked(false)
    this.opened = false
    this.events.emit(searchMapEvents.SEARCH_MAP_CLOSED)
  }

  _emptyResultsContainer () {
    this.resultsContainer.innerHTML = ''
    if (this.resultType === 'Accommodation' || (this.data && this.data.pagination.totalResults === 0)) {
      this.resultsContainer.classList.add(widgetQueries.noAccommodationResultsClass)
    } else {
      this.resultsContainer.classList.remove(widgetQueries.noAccommodationResultsClass)
    }
  }

  _addSearchResultsOnResultsContainer (searchResultsTemplate) {
    this._emptyResultsContainer()
    searchResultsTemplate.forEach(searchResultTemplate => {
      this.resultsContainer.innerHTML += searchResultTemplate
    })

    this.initAPI(this.resultsContainer)
  }

  initAPI (element) {
    Component.initDocumentComponentsFromAPI(element)
    Component.initComponentActionElements(element)
  }

  _createSearchResultTemplate (searchResultsData) {
    const searchResultsTemplate = searchResultsData.filter(searchResultData => !!searchResultData.geolocation).map(searchResultData => {
      return SearchResultTemplate({
        ...searchResultData,
        variant: 'compact',
        extraClasses: widgetQueries.searchResult,
        map: true,
        attributes: { [widgetQueries.searchResultIdAttr]: searchResultData.id }
      },
      this.locals)
    })
    return searchResultsTemplate
  }

  _attachSearchResultEvent (searchResultsTemplate) {
    Array.from(searchResultsTemplate.children).forEach(searchResultTemplate => {
      const searchResultMarker = this.mapApi.getMarker(searchResultTemplate.getAttribute(widgetQueries.searchResultIdAttr))
      const searchResultMarkerActiveClass = `${widgetQueries.mapInteractive}__custom-marker--active`
      searchResultTemplate.addEventListener('mouseenter', () => {
        if (!searchResultMarker.label.className.includes(searchResultMarkerActiveClass)) {
          this.mapApi.setLabelMarker(searchResultMarker, searchResultMarker.label.text, false, true, true)
          if (!searchResultMarker.getMap()) {
            this.cloneMarkerId = searchResultMarker.markerId + '_clone'
            this.clonedMarker = this.mapApi.getMarker(this.cloneMarkerId)
            if (!this.clonedMarker) {
              this.mapApi.addMarker(
                this.cloneMarkerId,
                searchResultMarker.position.lat(),
                searchResultMarker.position.lng(),
                searchResultMarker.icons,
                searchResultMarker.markerType,
                false,
                [],
                searchResultMarker.label.text,
                searchResultMarker.resultType
              )
              this.clonedMarker = this.mapApi.getMarker(this.cloneMarkerId)
            } else {
              this.clonedMarker.setMap(this.mapApi.getMap())
            }

            this._showClusterWithMarker(searchResultMarker.markerId, false)
            this.mapApi.setLabelMarker(this.clonedMarker, searchResultMarker.label.text, false, true, true)
          }
        }
      })
      searchResultTemplate.addEventListener('mouseleave', () => {
        if (!searchResultMarker.label.className.includes(searchResultMarkerActiveClass)) {
          this.mapApi.setLabelMarker(searchResultMarker, searchResultMarker.label.text, false, false)
          if (!searchResultMarker.getMap()) {
            this.clonedMarker.setMap(null)
            this.clonedMarker = null
            this._showClusterWithMarker(searchResultMarker.markerId, true)
          }
        }
      })
    })
  }

  _showClusterWithMarker (id, show) {
    const clusterer = this.mapApi.getMarkerClusterer()
    if (!clusterer) return
    if (show) {
      for (const marker in clusterer.markers_) {
        clusterer.markers_[marker].setVisible(show)
      }
    } else {
      const cluster = this._getClusterContainingMarker(clusterer, id)
      if (!cluster) return
      for (const marker in cluster.markers_) {
        cluster.markers_[marker].setVisible(show)
      }
    }
    clusterer.repaint()
  }

  _getClusterContainingMarker (clusterer, id) {
    const clusters = clusterer.getClusters()
    let foundCluster = null
    for (const cluster of clusters) {
      for (const marker of cluster.markers_) {
        if (marker.markerId === id) {
          foundCluster = cluster
          break
        }
      }
      if (foundCluster) {
        break
      }
    }

    return foundCluster
  }

  _addLoaderOnResultsContainer (add) {
    if (add) {
      const loader = LoaderTemplate({ extraClasses: 'w-search-map__results-loader' })
      this.resultsContainer.innerHTML = loader
      this.mapLoader.classList.add('active')
    } else {
      this.mapLoader.classList.remove('active')
    }
  }

  _getResultsToShow () {
    return (this.settings.useDestinationClustering && this.resultType !== 'Accommodation'
      ? this._buildDestinationResults(this.filtersCollection, this.resultType)
      : this.data.results) || []
  }

  _refreshMap (zoomEvent = false) {
    const results = this._getResultsToShow(this.resultType)

    if (results) {
      if (!results.length) {
        this._enableMap(true)
        this._emptyResultsContainer()
        return
      }
      this._setResultsMarkers(results, zoomEvent)

      this.updateResults(results)
      this._enableMap(true)
    }

    this.changedData = false
  }

  _setResultsMarkers (results, zoomEvent) {
    results.forEach((result, i) => {
      if (result.geolocation) {
        const latitude = parseFloat(result.geolocation.latitude)
        const longitude = parseFloat(result.geolocation.longitude)
        const icon = this.settings.marker
        const icons = {
          default: icon,
          active: false
        }
        this.mapApi.addMarker(
          result.id || result.value,
          latitude,
          longitude,
          icons,
          result.isUnavailable ? 'unavailable' : 'nearby',
          true,
          result.isDestination
            ? [{ type: 'click', callBack: this.zoomCluster.bind(this) }]
            : [{ type: 'click', callBack: this.clickOnPriceMarker.bind(this) },
                { type: 'mouseover', callBack: this._highlightSearchResult.bind(this) },
                { type: 'mouseout', callBack: this._highlightSearchResult.bind(this) }],
          result.isUnavailable ? ' ' : result.isDestination ? result.count.toString() : this._formatPrice(result.price),
          result.type
        )
      }
    })

    if (!zoomEvent) {
      this.mapApi.zoomToMarkers()
    }

    if (this.resultType === 'Accommodation' && this.settings.useAccommodationClustering) {
      setTimeout(() => {
        this.mapApi.createClusterMarkers({
          gridSize: parseInt(this.settings.gridSize),
          minimumClusterSize: parseInt(this.settings.minimumClusterSize)
        })
      }, delayMilliseconds)
    } else {
      this.mapApi.removeClusterMarkers()
    }
  }

  zoomCluster (ev, marker, map) {
    this.mapApi.panTo(marker.internalPosition)
    this._clearMap()
    this.events.emit('onClusterClick', marker)
  }

  _highlightSearchResult (ev, marker, map) {
    const highlightClassName = `${widgetQueries.searchResult}--highlighted`
    const searchResultSelected = this.resultsContainer.querySelector(`[${widgetQueries.searchResultIdAttr}="${marker.markerId}"]`)
    if (ev.domEvent.type === 'mouseover') {
      searchResultSelected.classList.add(highlightClassName)
    }
    if (ev.domEvent.type === 'mouseout') searchResultSelected.classList.remove(highlightClassName)
  }

  _formatPrice (price) {
    return `${price.currencySettings.symbolPosition === 'BeforeAmount' ? price.currencySettings.symbol : ''}${price.averagePrice}${price.currencySettings.symbolPosition === 'AfterAmount' ? price.currencySettings.symbol : ''}`
  }

  _clearMap () {
    this.mapApi.clearMarkers()
    this._clearInfoWindow()
    this._enableMap(false)
  }

  _showInfoCard (ev, marker, map) {
    if (this.activeInfoWindow) this.activeInfoWindow.close()
    this._clearInfoWindow()

    const result = this.data.results.find(r => r.id === marker.markerId)
    const cardHtml = SearchResultTemplate({
      ...result,
      variant: 'condensed-tiny',
      extraClasses: `${widgetApi}__search-result`,
      map: true
    },
    this.locals)
    this._createInfoWindow()
    this.infoWindowContainer.innerHTML = cardHtml

    this.initAPI(this.infoWindowContainer)

    window.google.maps.event.addListenerOnce(map, 'click', () => {
      this._clearInfoWindow()
      this._clearActiveMarker()
    })
  }

  clickOnPriceMarker (ev, marker, map) {
    this._showInfoCard(ev, marker, map)
    this.resultsBtn.click()
  }

  _clearActiveMarker () {
    const activeMarker = this.mapApi.getActiveMarker()
    if (activeMarker) {
      activeMarker.visited = true
      this.mapApi.setLabelMarker(activeMarker, activeMarker.label.text, false, false)
      this.mapApi.removeActiveMarker()
    }
  }

  _createInfoWindow () {
    this.infoWindowContainer = elementFromString(`<div class="${widgetQueries.infoWindowContainer}"></div>`)
    this.mapInteractiveContainer.appendChild(this.infoWindowContainer)
  }

  _clearInfoWindow () {
    if (this.infoWindowContainer) this.infoWindowContainer.remove()
    this.infoWindowContainer = null
  }

  updateResults (results) {
    if (results.every(r => r.isDestination)) {
      this._emptyResultsContainer()
      this.initAPI(this.resultsContainer)
    } else {
      const searchResultsTemplate = this._createSearchResultTemplate(results)
      this._addSearchResultsOnResultsContainer(searchResultsTemplate)
      this._attachSearchResultEvent(this.resultsContainer)
    }
  }

  _getLocales () {
    const customLocaleElement = document.querySelector(`[data-type="i18n"][data-uid="${this.searchElement.id}"]`)
    let customLocaleData = null
    try {
      customLocaleData = JSON.parse(customLocaleElement.textContent)
    } catch (err) { }

    return { ...globalLocales, ...componentLocales, ...(customLocaleData || {}) }
  }

  _openAcmModal () {
    if (this.opened) {
      this.searchModal.classList.add('w-search__modal-on-open-map')// z-index 1001
      const lastBackdrop = [...document.querySelectorAll('.c-backdrop.c-backdrop--ds.in')].slice(-1).pop()// take last opened backdrop
      lastBackdrop.classList.add('w-search__modal__backdrop-on-open-map')// z-index 1000
    }
  }

  _closeAcmModal () {
    if (this.opened) {
      this.searchModal.classList.remove('w-search__modal-on-open-map')
      const lastBackdrop = [...document.querySelectorAll('.c-backdrop.c-backdrop--ds.in')].slice(-1).pop()
      lastBackdrop.classList.remove('w-search__modal__backdrop-on-open-map')
    }
  }

  _buildDestinationResults (filters, resultType) {
    if (resultType === 'Country' || resultType === 'Region') {
      const filter = filters.models.find(model => model.getAttribute('type') === resultType || model.getAttribute('type') === FILTER_TYPES.DESTINATION)

      if (!filter) return

      const results = filter.values.models
        .filter(model => model.getValueFromMetaData('Type').toLowerCase() === resultType.toLowerCase() && model.getValueFromMetaData('Latitude') && model.getValueFromMetaData('Longitude') && model.getAttribute('isSelected') === this.destinationFiltered)
        .map(value => ({
          value: value.getAttribute('value'),
          type: resultType,
          isDestination: true,
          count: value.getAttribute('count'),
          geolocation: {
            latitude: value.getValueFromMetaData('Latitude'),
            longitude: value.getValueFromMetaData('Longitude')
          }
        }))

      if (results.length > 1) {
        return results
      } else {
        this.resultType = 'Accommodation'
        return this.data.results
      }
    }
  }

  _getGeoBoundingBox () {
    if (this.mapApi && this.opened) {
      const bounds = this.mapApi.getBounds()
      if (bounds) {
        const ne = bounds.getNorthEast()
        const sw = bounds.getSouthWest()
        const map = {
          Map: [ne.lat(), sw.lng(), sw.lat(), ne.lng()]
        }
        return map
      }
    }
  }

  _getLimit () {
    return this.settings.mapLimit
  }

  _changeActiveTab (button) {
    if (button.classList.contains(widgetQueries.resultsBtn)) {
      this.results.classList.remove(widgetQueries.resultsHideClass)
      this.mapFilters.classList.add(widgetQueries.filtersHideClass)
    } else if (button.classList.contains(widgetQueries.filtersBtn)) {
      this.results.classList.add(widgetQueries.resultsHideClass)
      this.mapFilters.classList.remove(widgetQueries.filtersHideClass)
      this.events.emit('filtersOpen')
    }
  }

  _isOpen () {
    return this.opened
  }

  _addEventsOnceToMap () {
    if (this.addedEvents) return
    if (this.settings.useDestinationClustering) {
      window.google.maps.event.addListener(this.mapApi.getMap(), 'zoom_changed', () => {
        const zoomLevel = this.mapApi.getMap().zoom
        if (zoomLevel <= maxCountryZoomLevel) {
          this.resultType = 'Country'
        } else if (zoomLevel > maxCountryZoomLevel && zoomLevel <= maxRegionZoomLevel) {
          this.resultType = 'Region'
        } else {
          this.resultType = 'Accommodation'
        }
      })
    }
    // With this the map would be reloaded automatically
    // window.google.maps.event.addListener(this.mapApi.getMap(), 'idle', () =>  this.onBoundsChangedDebounce())
    this.addedEvents = true
  }

  _setResultType (selectedValues, geoBoundingFiltered) {
    if (selectedValues.Destination) {
      const destinationFilter = this.filtersCollection.models.find(model => model.getAttribute('type') === FILTER_TYPES.DESTINATION)
      const selectedCountries = destinationFilter.values.models.filter(model => model.attributes.type === 'country' && model.attributes.isSelected)
      const selectedRegions = destinationFilter.values.models.filter(model => model.attributes.type === 'region' && model.attributes.isSelected && !selectedCountries.includes(model.attributes.parentId))
      const selectedCities = destinationFilter.values.models.filter(model => model.attributes.type === 'city' && model.attributes.isSelected && !selectedRegions.includes(model.attributes.parentId))

      const shouldStayAtRegionLevel = selectedCountries.length > 0 || selectedRegions.length > 1
      this.destinationFiltered = shouldStayAtRegionLevel
      if (shouldStayAtRegionLevel) {
        this.resultType = 'Region'
      } else if (selectedRegions.length === 1 || selectedCities.length > 0 || !this.settings.useDestinationClustering) {
        this.resultType = 'Accommodation'
      } else if (!geoBoundingFiltered) {
        this.resultType = 'Country'
      }
    } else {
      const shouldStayAtRegionLevel = selectedValues.Region && selectedValues.Region.length >= 2 && this.settings.useDestinationClustering
      if (shouldStayAtRegionLevel) {
        this.resultType = 'Region'
        this.destinationFiltered = true
        return
      }

      if (selectedValues.Region || selectedValues.City || !this.settings.useDestinationClustering) {
        this.resultType = 'Accommodation'
      } else if (selectedValues.Country) {
        this.resultType = 'Region'
      } else if (!geoBoundingFiltered) {
        this.resultType = 'Country'
      }
      this.destinationFiltered = false
    }
  }

  _openFilters () {
    if (this.opened) {
      this.filtersBtn.click()
      this.leftWrapper.classList.add('in')
      this.mapFilters.classList.add('in')
      const backdrop = elementFromString('<div class="w-search-map__filters-backdrop"></div>')
      this.element.appendChild(backdrop)
      backdrop.addEventListener('click', () => {
        this._closeFilters()
        this.searchFilters['c-side-drawer'].close()
      })
      const lastBackdrop = [...document.querySelectorAll('.c-backdrop.c-backdrop--ds.in')].slice(-1).pop()// take last opened backdrop
      lastBackdrop.style.display = 'none'
      this.events.emit('filtersOpen')
    }
  }

  _closeFilters () {
    if (this.opened) {
      this.leftWrapper.classList.remove('in')
      this.searchFilters.classList.remove('in')
      this.mapFilters.classList.remove('in')
      const backdrop = this.element.querySelector('.w-search-map__filters-backdrop')
      if (backdrop) backdrop.remove()
      this.mapApi.zoomToMarkers()
    }
  }

  _clickCloseFiltersBtn () {
    this._closeFilters()
  }
}

registerWidget(SearchMap, widgetApi)
