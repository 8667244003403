import { BulletListTemplate } from '../bullet-list/c-bullet-list.template'
import { SliderTemplate } from '../slider/c-slider.template'
import { defaultPriceData, PriceTemplate, getACMPriceLabel } from '../price/c-price.template'
import { defaultButtonData, BtnTemplate } from '../btn/c-btn.template'
import { BreadcrumbsTemplate, defaultBreadcrumbsData } from '../breadcrumbs/c-breadcrumbs.template'
import { language } from '../../../js/user/locale-settings'
import { register } from '../../../js/document/namespace'
import { getTokenClass } from '../../../js/helpers/tokens'
import { LabelTemplate } from '../label/c-label.template'
import { getProcessedCollectionDataFromItem } from '../../../js/helpers/collection-mapper'

// Import tokens
const tokensShared = require('./data/c-product-card__tokens.json')['c-product-card']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/components/product-card/data/c-product-card__tokens.json`)['c-package-block'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()

// Get global and search locales
const globalLocales = register(`window.sundio.i18n.${language}.global`)
const searchLocales = register(`window.sundio.i18n.${language}.search`)

const tokens = { ...tokensShared, ...tokensBrand }

const COMPONENT_LOCALES = register(`window.sundio.i18n.${language}.productCard`)

/**
 * The ProductCardData contains all data needed to hydrate a ProductCard view
 *
 * @typedef {Object}          ProductCardData
 *
 * @property {String}         id                              - The card id
 * @property {Boolean}        isFavorite                      - The card is included to favorites or not
 * @property {String[]}       images                          - The card images URLs
 * @property {String}         name                            - The accommodation name
 * @property {String[]}       usps                            - The accommodation usp's
 * @property {String}         urlWithFilters                  - The accommodation URL with applied search filters
 * @property {Object}        [collection]                     - The collection name associated with the card
 * @property {Number}        [userRating]                     - The user rating
 * @property {Number}        [rating]                         - The card category
 * @property {Object[]}      [locations]                      - The card locations
 * @property {String}        [mostBookedInDestinationText]    - Most booked text
 * @property {String[]}      [urgencyMessages]                - Urgency messages
 * @property {String}        [departureDateDetail]            - Departure date in human readable format
 * @property {String[]}      [featuredFilters]                - Featured applied filters to get a package offer
 * @property {Object[]}      [staticTexts]                    - Static texts
 *
 * @property {Object}        [price]                          - Card price object
 * @property {Object}        [price.yieldCampaignLogo]        - Price > Yield campaign logo
 * @property {String}        [price.yieldCampaignLogo.src]    - Price > Yield campaign logo src
 * @property {String[]}      [price.includedServices]         - Price > Included services
 * @property {Number}        [price.averagePrice]             - Price > main price to be displayed
 * @property {Object}        [price.currencySettings]         - Price > currencySettings
 * @property {String}        [price.currencySettings.symbol]  - Price > currencySettings > symbol
 * @property {String}        [price.currencySettings.symbolPosition]  - Price > currencySettings > symbolPosition (BeforeAmount, AfterAmount)
 * @property {Object}        [price.priceDiscounts]           - Price > appliedDiscounts
 * @property {String}        [price.priceDiscounts.discountPercentage]  - Price > appliedDiscounts > percentage
 * @property {String}        [price.priceDiscounts.originalPrice]  - Price > appliedDiscounts > Previous public price
 * @property {Number}        [price.publicPrice]              - Price > Previous public price
 * @property {Object}        [price.acmInformation]           - Price > ACM info
 * @property {String}        [price.acmInformation.acmUrl]    - Price > ACM info > API url
 * @property {Object}        [attributes]                     - Attributes for the product card
 */

export const defaultProductCardData = {
  id: '',
  isFavorite: false,
  isUnavailable: false,
  images: [],
  name: '',
  usps: [],
  urlWithFilters: '',
  extraClasses: '',
  collection: null,
  attributes: ''
}

export const ProductCardTemplate = (d, staticTexts) => {
  const origin = d.map ? 'map' : 'search-card'
  const collection = getProcessedCollectionDataFromItem(d)
  staticTexts = staticTexts || { ...globalLocales, ...searchLocales }
  d = { ...defaultProductCardData, ...d, collection }

  const featuredFilters = d.isUnavailable
    ? undefined
    : { mealplan: d.featuredFilters.mealplan, occupancy: d.featuredFilters.occupancy, departureDate: d.departureDateDetail, duration: d.featuredFilters.duration }

  return `
<div class="c-product-card"
${d.id && `data-acco-id="${d.id}"`}
${d.highestRatedCategory
  ? `data-highest-rated-category-name='${d.highestRatedCategory.name}' data-highest-rated-category-rating='${d.highestRatedCategory.rating}'`
  : ''}>
  <div class="c-product-card__content">

    <div class="c-product-card__content-header">
      <div class="c-product-card__content-header--rating">
      ${d.userRating
        ? `<div class="c-number-score c-number-score--squared">
              <div class="c-number-score__body">
              <span class="c-number-score__grade">${roudUserRating(d.userRating)}</span>
              </div>
              <span class="c-number-score__label">${staticTexts.userRating}</span>
          </div>`
        : ''}
      <button 
        class="m-button--clean c-favorite-btn c-product-card__favourite ${d.isFavorite ? 'is-active' : ''}" 
        data-js-component="c-favorite-btn" data-favorite__item-id="${d.id}" 
        data-favorite__parent-contextId="${d.parentContextItemId}"
        ${d.publicationCode ? `data-favorite__publication-code="${d.publicationCode}"` : ''}
      >
      </button>
      </div>
      <div class="c-product-card__image-wrapper">
        ${Array.isArray(d.images) && d.images.length > 0
          ? `${SliderTemplate({
                  luminosity: getTokenClass('luminosity', (d.luminosity ? d.luminosity : 'default'), tokens),
                  items: getSliderItems(d.images, d.urlWithFilters + '&originList=' + origin),
                  attributes: { 'data-arrow-keys': false }
                })}`
          : ''}
          ${d.collection ? `${LabelTemplate({ text: d.collection.text, collection: d.collection.id, extraClasses: 'c-product-card__collection' })}` : ''}
      </div>
    </div>

    <div class="c-product-card__content-footer">
      <div class="c-product-card__content-footer-title">
        ${d.rating ? `<span data-rating="${d.rating}" class="c-rating c-rating--s c-product-card__rating"></span>` : ''}
        <h2 class="c-product-card__name m-heading m-heading--medium">${d.name}</h2>
        ${d.locations ? BreadcrumbsTemplate({ ...defaultBreadcrumbsData, ...{ items: d.locations.map(location => ({ text: location.name, unclickable: location.unclickable })), extraClasses: 'c-product-card__breadcrumbs' } }) : ''}
        <button class="m-button--clean c-favorite-btn c-product-card__favourite ${d.isFavorite ? 'is-active' : ''}" data-js-component="c-favorite-btn" data-favorite__item-id="${d.id}" data-favorite__parent-contextId="${d.parentContextItemId}"></button>
      </div>
      <div class="c-product-card__content-footer-body">
        <ul class="c-bullet-list o-list-bare c-product-card__usps">
          ${d.usps.map(usp => `<li class="c-bullet-list__item"><span class="c-bullet-list__icon m-icon--checkmark o-bullet__icon"></span><span class="c-bullet-list__text o-bullet__text o-bullet__text--middle">${usp}</span></li>`).join('').trim()}
        </ul>
        ${d.urgencyMessages && d.urgencyMessages.length
          ? `<ul class="c-bullet-list o-list-bare c-product-card__urgency-messages">
              ${d.urgencyMessages.map(urgencyMessage => `
              <li class="c-bullet-list__item"><span class="c-bullet-list__icon m-icon--fire o-bullet__icon"></span> <span class="c-bullet-list__text o-bullet__text o-bullet__text--middle">${urgencyMessage}</span></li>
              `).join('').trim()}
            </ul>`
          : ''}
        <div class="c-product-card__content-footer-campaigns">
          ${d.campaign ? `<div class="c-product-card__action-wrapper"><button type="button" class="c-product-card__action cs-state-info cs-state-info--light" data-js-component="c-btn" data-c-modal__action="open" data-c-modal__source="${d.campaignUrl}" data-c-modal__id="w-search__modal">${d.campaign.title}<i class="c-product-card__action-icon m-icon--information-circle"></i></button></div>` : ''}
          ${!d.isUnavailable
              ? d.price.yieldCampaignLogo && d.price.yieldCampaignLogo.src ? `<img class="c-product-card__yield" src="${d.price.yieldCampaignLogo.src}">` : ''
            : ''
          }
        </div>
      </div>
    </div>
  </div>
  ${getFooter(d, featuredFilters, staticTexts, defaultButtonData, defaultPriceData)}
  <a class="c-product-card__link" href="${d.urlWithFilters + '&originList=' + origin}"></a>
  <a class="c-product-card__link" href="${d.urlWithFilters + '&originList=' + origin}" target="_blank"></a>
</div>`
}

function GetIncludedServices (includedServices) {
  const locales = { ...COMPONENT_LOCALES }
  return Object.keys(includedServices).filter(service => service !== 'accommodation').map(service => (
    { icon: getTokenClass('icon', service, tokens), text: includedServices[service] !== '' ? includedServices[service] : locales[service] }
  ))
}

function getSliderItems (images, urlWithFilters, variant) {
  return images.map(i => getImageTemplateForSlider(i, urlWithFilters, variant))
}

function getParametersToRescaleImage () {
  return 'width=320&height=240&scale=both&mode=crop'
}

function getImageTemplateForSlider (imageUrl, urlWithFilters, variant) {
  const ratio = variant === 'compact' ? 'o-ratio--2:1' : 'o-ratio--16:9 o-ratio--4:3@sm'
  return `
  <a href="${urlWithFilters}">
    <div class="c-img o-ratio ${ratio} c-search-result__image" data-img-src="${imageUrl}?width={W}&height={H}&scale=both&mode=crop" data-js-component="c-img" data-c-img__resolve="intersect">
      <img src="${imageUrl}?${getParametersToRescaleImage()}" class="c-img__placeholder o-ratio__content">
    </div>
  </a>
    `
}

function roudUserRating (rating) {
  return Math.round(rating * 10) / 10
}

export function getFooter (d, featuredFilters, staticTexts, defaultButtonData, defaultPriceData) {
  return `
  <div class="c-product-card__footer ${d.isUnavailable ? 'unavailable' : ''}">
  ${d.isUnavailable
    ? `<div class="c-product-card__footer-unavailable-info">
        <div class="c-product-card__footer-unavailable-info-title m-body m-body--small">${staticTexts.unAvailableTitle}</div>
        <div class="c-product-card__footer-unavailable-info-text  m-body m-body--small">${staticTexts.unAvailableText}</div>
        ${BtnTemplate({
          ...defaultButtonData,
          variant: 'default',
          text: staticTexts.subscribe,
          link: {
            href: d.urlWithFilters + '&originList=' + origin,
            title: staticTexts.subscribe
          },
          extraClasses: 'c-product-card__cta c-product-card__unavailable-button'
        })}
      </div>
  `
  : d.price
    ? `${BulletListTemplate({
        items: GetIncludedServices({ ...d.price.includedServices, ...featuredFilters }),
        extraClasses: 'c-product-card__footer-services'
      })}
    <div class="c-product-card__footer-price">
      ${PriceTemplate({
        ...defaultPriceData,
        extraClasses: 'c-search-card__price',
        value: d.price.averagePrice,
        currency: d.price.currencySettings.symbol,
        currencyPosition: d.price.currencySettings.symbolPosition === 'BeforeAmount' ? 'before' : 'after',
        saleText: d.price.priceDiscounts?.discountPercentage
          ? `-${d.price.priceDiscounts.discountPercentage}%`
          : d.price.publicPrice?.discountPercentage
            ? `-${d.price.publicPrice.discountPercentage}%`
            : undefined,
        oldPrice: d.price.priceDiscounts?.originalPrice
          ? d.price.priceDiscounts.originalPrice
          : undefined,
        acmUrl: d.price.acmInformation?.acmUrl
          ? d.price.acmInformation.acmUrl
          : undefined,
        acmModalId: d.price.acmInformation?.acmUrl
          ? 'w-search__modal'
          : undefined,
        acmHideInfoIcon: !!d.price?.mandatoryExtraCostsText,
        legend: staticTexts.priceLegend,
        publicPrice: d.price.publicPrice
          ? `${staticTexts.publicPrice} ${d.price.publicPrice.price} ${d.price.currencySettings.symbol}`
          : undefined,
        discount: d.price.priceDiscounts && d.price.priceDiscounts.originalPrice
          ? d.price.priceDiscounts.originalPrice - d.price.averagePrice
          : undefined,
        discountText: staticTexts.discountSaveText,
        publicPriceDetail: d.price.skiPassPublicPriceText,
        label: d.price?.priceDiscounts?.description,
        label2: d.price?.mandatoryExtraCostsText && d.price.acmInformation
          ? getACMPriceLabel({ ...d, text: d.price.mandatoryExtraCostsText, staticText: d.price.staticText, acmUrl: d.price.acmInformation.acmUrl, modalId: 'w-search__modal' })
          : d.price.staticText,
        labelGrayed: true
      })}
      
      ${BtnTemplate({
        ...defaultButtonData,
        variant: 'flow',
        text: staticTexts.button,
        link: {
          href: d.urlWithFilters + '&originList=' + origin,
          title: staticTexts.button
        },
        extraClasses: 'c-product-card__cta'
      })} 
      ${BtnTemplate({
        ...defaultButtonData,
        variant: 'flow',
        text: staticTexts.button,
        link: {
          href: d.urlWithFilters + '&originList=' + origin,
          title: staticTexts.button,
          target: '_blank'
        },
        extraClasses: 'c-product-card__cta'
      })} 
    </div>`
    : ''}
    </div>
  `
}
