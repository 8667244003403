import registeredEvents from '../../../js/helpers/registered-events'
import { priceFilterEvents } from '../../../js/document/event-types'

const widgetApi = 'w-price-filter'
const widgetQueries = {
  range: `[data-${widgetApi}__range]`,
  track: 'data-track'
}

const EventEmitter = require('eventemitter3')

export default class PriceFilter {
  /**
   * Creates a new PriceFilter
   *
   * @constructor
   *
   * @param {HTMLElement} element - The element where to attach PriceFilter
   * @param {FilterModel} filterModel - The associated FilterModel
   */
  constructor (element, filterModel) {
    this.element = element

    this.rangeElement = this.element.querySelector(widgetQueries.range)
    this.rangeApi = this.rangeElement['c-range']

    this.events = new EventEmitter()

    this.setFilterModel(filterModel)

    // Bind range events to filterModel
    this.rangeApi.events.on('change', () => {
      this.setRangeOptionsToFilter()
    })

    registeredEvents.registerWidgetEvents(widgetApi, this.events, {
      ...this.element.hasAttribute(widgetQueries.track) && { track: this.element.attributes[widgetQueries.track].value }
    })
  }

  /**
   * Set the current filterModel
   * - Set given filterModel to filterModel self property
   * - Set options on range
   * - Bind filter model events
   *
   * @param {FilterModel} filterModel - The associated FilterModel
   *
   * @returns {PriceFilter} self instance
   */
  setFilterModel (filterModel) {
    this.filterModel = filterModel
    // Setting options silently so that value prop changed is not triggered in case it changes when changing options
    const setRangeOptions = () => this.rangeApi.setProp('options', this.getRangeOptionsFromFilter(), { silent: true })

    setRangeOptions()

    this.filterModel.events.on('change', () => {
      setRangeOptions()
      this.events.emit(priceFilterEvents.PRICE_FILTERS_APPLIED, this.filterModel.getSelectedModels().map(
        (fm) => {
          return fm.attributes.caption
        })
      )
    })

    return this
  }

  /**
   * Set the current Range selectedValues to filter
   *
   * @returns {PriceFilter} self instance
   */
  setRangeOptionsToFilter () {
    const options = this.rangeApi.getProp('options')
    const selectedOptions = this.getSelectedOptions(options)

    this.filterModel
      .clearSelection({ silent: true })
      .setSelectedValues(selectedOptions)
    return this
  }

  getSelectedOptions (options) {
    const minIndex = this.rangeApi.getProp('rangeMin')
    const maxIndex = this.rangeApi.getProp('rangeMax')

    const selectedOptions = options.filter((option) => option.selected).map((option) => option.value)

    return selectedOptions[0] === options[minIndex].value && selectedOptions[selectedOptions.length - 1] === options[maxIndex].value
      ? []
      : selectedOptions
  }

  /**
   * Get the current Range options from filter
   *
   * @returns {RangeOptionData[]} The parsed filter to Dropdown options
   */
  getRangeOptionsFromFilter () {
    // Adding the default option as selected, in case no filterModel value is selected
    return this.filterModel.values.models.map(model => ({
      value: model.getAttribute('value'),
      text: model.getAttribute('caption'),
      selected: model.getAttribute('isSelected')
    }))
  }
}
