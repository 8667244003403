import FiltersCollection from '../../../js/data/filters/filters-collection'
import { buildUrlWithParams, getUrlFromString } from '../../../js/document/url'
import { registerWidget } from '../../../js/core/widget/widget-directory'
import { debounce } from '../../../js/utils'
import { SearchResultTemplate } from '../../components/search-result/c-search-result.template'
import { ProductCardTemplate } from '../../components/product-card/c-product-card.template'
import { publicPriceDetailTemplate } from '../../components/price/c-price.template'
import { SearchMessageTemplate } from '../../components/search-message/search-message.template'
import { ChipTemplate } from '../../components/chip/c-chip.template'
import domEventsHelper from '../../../js/document/dom-events-helper'
import { toCamelCase } from '../../../js/helpers/string'
import { bindClickEventToResults } from '../../../js/helpers/event-binder'
import Component from '../../../js/core/component/component'
import Img from '../../components/img/main'
import { fetchJsonData } from '../../../js/helpers/json-fetch'
import { register } from '../../../js/document/namespace'
import { language } from '../../../js/user/locale-settings'
import { CollectionTeaserTemplate } from '../../components/collection-teaser/c-collection-teaser.template'
import { processCollectionTeaserData } from '../../components/collection-teaser/data-processor'
import { GET_WHITELISTED_PARAMS, EXCLUDE_WHITELISTED_PARAMS } from '../../../js/helpers/white-listed-params'
import { GET_PASSTHROUGH_PARAMS } from '../../../js/helpers/pass-through-params'
import AccoLister from '../../widgets/acco-lister/main'
import {
  WIDGET_API,
  COLLAPSE_API,
  MAP_API,
  ELEMENT_QUERIES,
  KNOWN_FILTER_VIEWS,
  HIDDEN_FILTER_TYPES,
  FILTERS_ON_TOP_ELEMENT_QUERIES,
  getSearchParamsFromUrl,
  sanitizeQueryString,
  removeDefaultQueryParams
} from './config'
import { arrayifyObject, derrayifyObject } from '../../../js/helpers/arrayify-object'
import { removeUndefinedKeys } from '../../../js/helpers/object'
import registeredEvents from '../../../js/helpers/registered-events'
import { searchEvents, favoriteEvents } from '../../../js/document/event-types'
import { BtnTemplate } from '../../components/btn/c-btn.template'
import { elementFromString } from '../../../js/document/html-helper'
import { buildSearchWidgetOptions } from './searchWidgetOptions'
import { FILTER_TYPES, DESTINATION_FILTER_TYPES, TRANSPORT_TYPE_VALUES } from '../../../js/data/filters/config'

import eventBus, {
  PRICE_RANGE_FILTER_ID,
  SEARCH_FILTERS_INIT,
  SEARCH_API_RESPONSE_SUCCESS,
  SEARCH_PRICE_RANGE_CHANGE
} from '@sunwebgroup/domain-events'

import { adaptPriceRangeChangePayload } from './adaptPriceRangeChangePayload'

const widgetApi = 'w-search'

const defaultAccoTipsPosition = 2

const defaultAccoTipsPerSlide = 1

// Room length / occupancy thhreshold. Rooms with higher capacity will be shown a warning message (see method 'updateWarningMessagesVisibility').
const RoomLengthThreshold = 3

const EventEmitter = require('eventemitter3')
require('../search-map/main')
const globalLocales = register(`window.sundio.i18n.${language}.global`)
const componentLocales = register(`window.sundio.i18n.${language}.search`)

// Scroll config
const config = {
  debounceDelay: 150,
  scroll: { block: 'start', behavior: 'smooth' },
  hiddenParams: ['contextitemid', 'isFirstUserRequest'],
  offFilterParams: ['offset', 'sort', 'limit']
}

// Definition of custom transformations to be applied on some filters data before send it o a request
const customFilterDataTransformations = {
  PricePerPerson: (values) => [values[0], values[values.length - 1]],
  DepartureDate: (values) => [values[0], values[values.length - 1]]
}

const customChipsTextTransformations = {
  PricePerPerson: (searchApi, filter) => customFilterDataTransformations[filter.type](filter.captions).join(' - '),
  DepartureDate: (searchApi, filter) => searchApi.filterViews.find(v => v.name === filter.type).instance.textBoxInput.value,
  Destination: (searchApi, filter) => searchApi.filterViews.find(v => v.name === filter.type).instance[ELEMENT_QUERIES.destinationMultipleFilter].getSelectedOptions()
}

export default class Search {
  /**
   * Creates a new Search
   *
   * @constructor
   *
   * @param {HTMLElement} element - The element where to attach QuickSearch
   * @param {Object} options
   * @param {String} options.url - The url where to fetch data
   */
  constructor (element, options = {}) {
    this.element = element
    this.events = new EventEmitter()
    this.element[widgetApi] = {
      events: this.events
    }
    this.elements = this.runElementQueries()
    this.childComponents = this.accessChildComponentsApis()
    this.url = options.url || this.element.getAttribute(ELEMENT_QUERIES.urlAttr)
    this.toggleCardTemplate = this.element.hasAttribute(ELEMENT_QUERIES.toggleProductCard)
    this.useProductCardFooter = this.element.hasAttribute(ELEMENT_QUERIES.useProductCardFooter)
    this.campaignDetailsUrl = options.campaignDetailsUrl || this.element.getAttribute(ELEMENT_QUERIES.campaignDetailsUrl)
    this.publicPricesUrl = options.publicPricesUrl || this.element.getAttribute(ELEMENT_QUERIES.publicPricesUrl)

    registeredEvents.registerWidgetEvents(widgetApi, this.events, {
      ...this.element.hasAttribute(ELEMENT_QUERIES.trackAttr) && { track: this.element.attributes[ELEMENT_QUERIES.trackAttr].value }
    })

    // Helper collection of destination filters where to store if the filter has selected values or not
    this.destinationFilters = DESTINATION_FILTER_TYPES.map(filter => { return { name: filter, selected: false } })

    this.whiteListedParams = GET_WHITELISTED_PARAMS(getSearchParamsFromUrl(document.location))
    this.passThroughParams = GET_PASSTHROUGH_PARAMS(getSearchParamsFromUrl(document.location))

    const defaultQueryString = this.element.getAttribute(ELEMENT_QUERIES.defaultQueryStringAttr)
    const isResultOnly = this.element.hasAttribute(ELEMENT_QUERIES.resultOnlyAttr)
    if (!isResultOnly) this.insertSelectedFiltersContainerToFiltersComponent()

    const sanitizedQueryString = sanitizeQueryString(defaultQueryString)

    // Recover defaultQueryString params with config.getSearchParamsFromUrl method that uses window.URL object functionalities
    // We use document.location.origin to form a valid url, but its value is not taken into account by getSearchParamsFromUrl
    const defaultQueryParams = sanitizedQueryString ? getSearchParamsFromUrl(`${document.location.origin}?${sanitizedQueryString}`) : {}

    this.queryParams = {
      ...this.getExtraParamsFromDom(),
      ...this.correctParams(defaultQueryParams)
    }
    this.selectedValues = {
      ...this.getParticipantsDataFromComponent(),
      ...this.getSelectedValuesFromURL()
    }
    this.offFilterParams = {}
    config.offFilterParams.forEach(param => {
      if (this.selectedValues[param]) {
        this.offFilterParams[param] = this.selectedValues[param]
        delete this.selectedValues[param]
      } else if (param === 'sort' && this.queryParams[param]) {
        this.offFilterParams[param] = this.queryParams[param]
      }
    })

    this.isFirstLoad = true

    // Get the local strings
    this.locales = this._getLocales()

    // Bind self change events to self update
    this.debouncedOnChangeFilters = debounce(this.onChangeFilters, config.debounceDelay)
    this.events.on('change', (ev) => this.debouncedOnChangeFilters(ev))

    this.bindEvents()
    this.resultsRenderedEvent = new window.CustomEvent('SearchResultsRendered')
    this.fetchAndUpdate()

    // Remove defaultQueryParams after first request
    this.queryParams = removeDefaultQueryParams(defaultQueryParams, this.queryParams)
    this.filtersOnTopCollapsed = false

    this.collapseText = ''
    this.modifyText = ''
    this.toggleFilterOnTopLoader(true)
    this.boundingBox = ''

    if (!isResultOnly) this.addShowHideFiltersSummaryEvents()

    ///

    /*
      - If the value is the same as the default, remove it from the uri (this logic may be implemented somewhere in this class)
      - Transform values from MVC to React world correctly (the 99999 -> null).
     */
    const { PricePerPerson } = this.selectedValues

    eventBus.emit(SEARCH_FILTERS_INIT, {
      ...(PricePerPerson
        ? {
            [PRICE_RANGE_FILTER_ID]: {
              value: this.selectedValues.PricePerPerson.map(s => {
                if (s === '999999') return null
                return parseInt(s, 10)
              })
            }
          }
        : {})
    })

    eventBus.on(SEARCH_PRICE_RANGE_CHANGE, ({ value, configuration }) => {
      const adaptedRange = adaptPriceRangeChangePayload(configuration, value)
      const filterModel = this.filtersCollection.findWhere('type', FILTER_TYPES.PRICE_PER_PERSON)

      filterModel.clearSelection({ silent: true })
      filterModel.setSelectedValues(adaptedRange)
    })
  }

  /*
  * -----------------------------------------------------
  * INITIALIZATION RELATED METHODS
  * -----------------------------------------------------
  * */

  /**
   * Runs element queries
   *
   * @returns {Object}
   */
  runElementQueries () {
    return {
      extraParams: Array.from(this.element.querySelectorAll(ELEMENT_QUERIES.extraParams))
        .reduce((obj, element) => ({ ...obj, [element.name]: element }), {}),
      sortElements: this.element.querySelectorAll(ELEMENT_QUERIES.sortElement),
      selectedFilters: this.element.querySelector(ELEMENT_QUERIES.selectedFilters),
      selectedFiltersChips: this.element.querySelector(ELEMENT_QUERIES.selectedFiltersChips),
      selectedFiltersClear: this.element.querySelector(ELEMENT_QUERIES.selectedFiltersClear),
      filtersWrapper: this.element.querySelector(ELEMENT_QUERIES.filtersElement),
      filtersTopWrapper: document.querySelector(ELEMENT_QUERIES.filtersTopElement),
      filters: document.querySelectorAll(ELEMENT_QUERIES.filterElements(this.element.id)),
      filterGroups: this.element.querySelectorAll(ELEMENT_QUERIES.filterGroups),
      totalResults: this.element.querySelector(ELEMENT_QUERIES.totalResults),
      availableResults: this.element.querySelector(ELEMENT_QUERIES.availableResults),
      pagination: this.element.querySelector(ELEMENT_QUERIES.paginationElement),
      results: this.element.querySelector(ELEMENT_QUERIES.resultsElement),
      errorMessage: this.element.querySelector(ELEMENT_QUERIES.errorMessage),
      noResultsMessage: this.element.querySelector(ELEMENT_QUERIES.noResultsMessage),
      clearFiltersTrigger: this.element.querySelector(ELEMENT_QUERIES.clearFiltersTrigger),
      collapseElements: this.element.querySelectorAll(ELEMENT_QUERIES.collapseElements),
      filtersOnTopCollapse: document.querySelector(ELEMENT_QUERIES.filtersOnTopCollapse),
      filtersOnTopQSWrapper: document.querySelector(FILTERS_ON_TOP_ELEMENT_QUERIES.filtersOnTopQSWrapper),
      filtersOnTopCollapsedContainer: document.querySelector(FILTERS_ON_TOP_ELEMENT_QUERIES.filtersOnTopCollapsedContainer),
      filtersOnTopWrapper: document.querySelector(FILTERS_ON_TOP_ELEMENT_QUERIES.filtersOnTopWrapper),
      filtersOnTopParticipants: document.querySelector(FILTERS_ON_TOP_ELEMENT_QUERIES.filtersOnTopParticipants),
      filtersOnTopMinimalIcon: document.querySelector(FILTERS_ON_TOP_ELEMENT_QUERIES.filtersOnTopMinimalIcon),
      filtersOnTopMinimalText: document.querySelector(FILTERS_ON_TOP_ELEMENT_QUERIES.filtersOnTopMinimalText),
      filtersOnTopCollapseIcon: document.querySelector(FILTERS_ON_TOP_ELEMENT_QUERIES.filtersOnTopCollapseIcon),
      filtersOnTopCollapseText: document.querySelector(FILTERS_ON_TOP_ELEMENT_QUERIES.filtersOnTopCollapseText),
      filtersOnTop: document.querySelector(FILTERS_ON_TOP_ELEMENT_QUERIES.filtersOnTop),
      filtersOnTopOverlay: document.querySelector(FILTERS_ON_TOP_ELEMENT_QUERIES.filtersOnTopOverlay),
      filtersOnTopLoader: document.querySelector(FILTERS_ON_TOP_ELEMENT_QUERIES.filtersOnTopLoader),
      mapElement: document.querySelector(ELEMENT_QUERIES.mapElement),
      filtersMobileButton: document.querySelector(ELEMENT_QUERIES.filtersMobileButton),
      showMapButtons: document.querySelectorAll(ELEMENT_QUERIES.showMapButton),
      searchFiltersBodyContent: this.element.querySelector(ELEMENT_QUERIES.searchFiltersBodyContent),
      favCleanerButton: document.querySelector(ELEMENT_QUERIES.favCleanerButton),
      messageWrapper: document.querySelector(ELEMENT_QUERIES.messageWrapper),
      participantsWarningTitle: this.element.querySelector(ELEMENT_QUERIES.participantsWarningTitle),
      participantsWarningMessage: this.element.querySelector(ELEMENT_QUERIES.participantsWarningMessage),
      popularityTooltips: this.element.querySelectorAll(ELEMENT_QUERIES.popularityTooltip)
    }
  }

  /**
   * Access to child components APIs
   *
   * @returns {Object}
   */
  accessChildComponentsApis () {
    return {
      pagination: (this.elements.pagination ? this.elements.pagination['c-pagination'] : null),
      sort: (this.elements.sortElements ? [...this.elements.sortElements].map(element => element['c-dropdown']) : null),
      collapseAPIs: [...this.elements.collapseElements].map(function (element) {
        return element[COLLAPSE_API]
      }),
      map: (this.elements.mapElement ? this.elements.mapElement[MAP_API] : null)
    }
  }

  /**
   * Gets extra params through hidden inputs
   *
   * @returns {Object|undefined}
   */
  getExtraParamsFromDom () {
    const extraParams = Object.values(this.elements.extraParams)
    return extraParams
      ? extraParams.reduce((obj, el) => {
        obj[el.name] = el.value
        return obj
      }, {})
      : undefined
  }

  /**
 * Gets participants data from component in element if any
 * Returns a multidimensional array with the participants (BirthDates) organized into rooms allocation,
 * eg. [ ['1979-01-01','1985-03-03'], ['2013-01-01','2015-03-03'] ]
 *
 * @returns {[DateString[]]|undefined} Matching participants for that room
 */
  getParticipantsDataFromComponent () {
    const participantsSelectorAPI = this._getParticipantsSelectorAPI()

    return participantsSelectorAPI
      ? {
        // Check if QueryParams has Participants and Allocation defined, as this would mean they have been set in the DefaultQueryString
          Participants: this.queryParams.Participants ? this.queryParams.Participants : this.participantsSelectorAPI.getParticipantsData() || undefined,
          Allocation: this.queryParams.Allocation ? this.queryParams.Allocation : this.participantsSelectorAPI.getAllocation() || undefined
        }
      : {}
  }

  /**
  * Gets participants age profiles data
  * Returns an array of participants amount grouped by age profile (showing the first character)
  * eg. ['a3', 'b2', 'c1']
  *
  * @returns string[]
  */
  getParticipantsAgeProfiles () {
    const participantsSelectorAPI = this._getParticipantsSelectorAPI()

    let result = []
    if (participantsSelectorAPI) {
      const ageProfiles = participantsSelectorAPI.getParticipantsByAgeProfiles()
      result = Object.entries(ageProfiles).map(([key, val]) => {
        return key.charAt(0) + val.length
      })
    }
    return result
  }

  _getParticipantsSelectorAPI () {
    if (!this.participantsSelectorAPI) {
      const participantsSelector = document.querySelector('[data-js-component="c-participants-selector"]')
      this.participantsSelectorAPI = participantsSelector && participantsSelector['c-participants-selector']
    }
    return this.participantsSelectorAPI
  }

  /**
   * Init events chip, pagination...
   *
   * @returns {Search} self instance
   */
  bindEvents () {
    // Bind pagination event
    if (this.childComponents.pagination) {
      this.childComponents.pagination.events.on('change', (data) => {
        const offset = ((data.currentPage - 1) * this.pagination.limit) || 0
        const limit = this.pagination.limit
        this.offFilterParams = {
          ...this.offFilterParams,
          offset,
          limit
        }

        this.events.emit(searchEvents.SEARCH_MODIFIER_CHANGE,
          {
            event: searchEvents.SEARCH_PAGINATION_CHANGE,
            value: data.currentPage
          })

        this.elements.results.scrollIntoView(config.scroll) // FIXME weird behaviour in IE
        this.events.emit('change')
      })
    }

    if (this.elements.clearFiltersTrigger) {
      this.elements.clearFiltersTrigger.addEventListener('click', e => {
        e.preventDefault()
        this.onClickClearSelectedFilters()
      })
    }

    // Bind sort event
    if (this.childComponents.sort) {
      [...this.childComponents.sort].forEach(element => {
        element.events.on('propChanged', (changes) => {
          if (changes.name === 'value') {
            this.offFilterParams = {
              ...this.offFilterParams,
              sort: changes.value || null,
              offset: 0 // reset pagination
            }

            this.events.emit(searchEvents.SEARCH_MODIFIER_CHANGE,
              {
                event: searchEvents.SEARCH_SORT_CHANGE,
                value: changes.value
              })
          }
          this.events.emit('change')
        })
      })
    }
    // Bind map button event
    if (this.elements.showMapButtons) {
      this.elements.showMapButtons.forEach(btn => {
        btn.addEventListener('click', e => {
          this.events.emit(searchEvents.SEARCH_MAP_OPENED,
            {
              event: searchEvents.SEARCH_MAP_OPENED,
              value: 'opened'
            })
          this.updateFiltersOnTopInfoElements()
        })
      })
    }

    if (this.childComponents.map) {
      this.childComponents.map.events.on('onRefreshMap', (marker) => this.fetchAndUpdateMap(marker))
      this.childComponents.map.events.on('onClusterClick', (marker) => this.fetchAndUpdateMap(marker))
      this.childComponents.map.events.on('filtersOpen', () => this.updateFilterGroupsVisibility())
    }

    // Bind DOM events

    this._domEvents = [
      [this.elements.filtersOnTopCollapse, { click: (ev) => this.onFilterOnTopCollapse(ev) }],
      [this.elements.filtersOnTopOverlay, { click: (ev) => this.onFilterOnTopCollapse(ev) }],
      [this.elements.selectedFiltersChips, { click: (ev) => this.onClickSelectedFiltersChip(ev) }]
    ]
    domEventsHelper.attachEvents(this._domEvents, WIDGET_API)
  }

  /**
   * Creates a new filters collection, and bind updated event to fire a Search change event
   *
   * @param {Object[]} filtersData - The filters data
   *
   * @returns {FiltersCollection}
   */
  initFiltersCollection (filtersData = []) {
    const filtersCollection = new FiltersCollection(filtersData)
    filtersCollection.events.on('updated', () => {
      this.offFilterParams = {
        ...this.offFilterParams,
        offset: 0
      }
      this.selectedValues = filtersCollection.getFiltersSelectedValuesObject()

      const anySideBarFilterSelected = this._isAnySideBarFilterSelected(this.selectedValues)
      this.events.emit('change', { clearFilters: !anySideBarFilterSelected })
    })
    return filtersCollection
  }

  /**
   * Init or update filters
   *
   * @returns {Search} self instance
   */
  initFilters () {
    // Init or update filters
    this.filterViews = this.filterViews || []
    this.elements.filters.forEach(el => {
      // Get the element filter view, and check if it's known & available
      const filterView = el.getAttribute(ELEMENT_QUERIES.filterViewAttr)
      if (!KNOWN_FILTER_VIEWS[filterView]) return

      // Check the filter name
      const filterName = el.getAttribute(ELEMENT_QUERIES.filterNameAttr) ||
        el.getAttributeNames().filter((attrKey) => attrKey.indexOf(ELEMENT_QUERIES.filterNameAttr) === 0)

      // Get the element filter data, and check if it's available
      const filterData = !Array.isArray(filterName)
        ? this.filtersCollection.findWhere('type', filterName)
        : filterName.map((attrKey) => [
          toCamelCase(attrKey.replace(`${ELEMENT_QUERIES.filterNameAttr}-`, '')),
          el.getAttribute(attrKey)
        ]).reduce((obj, [attrKey, attrValue]) => ({
          ...obj,
          [attrKey]: this.filtersCollection.findWhere('type', attrValue)
        }), {})

      // Add or remove the is-empty class accordingly (show/hide)
      this._setFiltersVisibility(el, filterName, filterData)

      if (!filterData) return

      // Init or update the view with associated data if necessary
      const name = !Array.isArray(filterName) ? filterName : filterName.map((attrKey) => el.getAttribute(attrKey)).join('_')
      const filterViewObject = this.filterViews.find(v => v.name === name)
      filterViewObject
        ? filterViewObject.instance.setFilterModel(filterData)
        : this.filterViews.push({
          name,
          view: filterView,
          instance: new KNOWN_FILTER_VIEWS[filterView](el, filterData),
          data: filterData
        })
    })
    return this
  }

  _isAnySideBarFilterSelected (selectedValuesObj) {
    const filtersOnTopNames = this._getFiltersOnTopNames()
    const otherExcludedFilters = [FILTER_TYPES.PARTICIPANTS, FILTER_TYPES.PARTICIPANTS_DISTRIBUTION]
    const excludedFilters = [...filtersOnTopNames, ...otherExcludedFilters]

    let anySideBarFilterSelected = false

    for (const [key, value] of Object.entries(selectedValuesObj)) {
      if (!excludedFilters.includes(key) && value != null) { // By coercion it checks null and undefined.
        anySideBarFilterSelected = true
      }
    }

    return anySideBarFilterSelected
  }

  _setFiltersVisibility (element, filterName, filterData) {
    let shouldHide = !filterData
    if ((filterName === FILTER_TYPES.DEPARTURE_AIRPORT || filterName === FILTER_TYPES.ARRIVAL_AIRPORT) && (FILTER_TYPES.TRANSPORT_TYPE in this.selectedValues) && this.selectedValues.TransportType === TRANSPORT_TYPE_VALUES.BUS) {
      shouldHide = true
    }
    element.classList.toggle('is-empty', shouldHide)
    element.parentElement.classList.toggle('has-empty-child', shouldHide)
  }

  /**
   * Return a locale strings object
   */
  _getLocales () {
    const customLocaleElement = document.querySelector(`[data-type="i18n"][data-uid="${this.element.id}"]`)
    let customLocaleData = null
    try {
      customLocaleData = JSON.parse(customLocaleElement.textContent)
    } catch (err) { }

    return { ...globalLocales, ...componentLocales, ...(customLocaleData || {}) }
  }

  /*
  * -----------------------------------------------------
  * DATA & REQUEST RELATED METHODS
  * -----------------------------------------------------
  * */

  /**
   * Fetch new data and update widget with it
   *
   * - Disable UI
   * - Update QueryParams & RequestUrl
   * - Request new data
   * - Create or update filters collection
   * - Update filters UI
   * - Update total results
   * - Update selected filters
   * - Update Results
   * - Update Pagination
   * - Enable UI
   *
   * @param {Object} [options]
   * @returns {Promise}
   */
  async fetchAndUpdate (options = {}) {
    this.setEnabledState(false)
    let limit = this.offFilterParams.limit
    let isMap

    if (this.childComponents.map) {
      this.childComponents.map.clearMap()
      if (this.childComponents.map.isOpen()) {
        limit = this.childComponents.map.getLimit()
        isMap = true
        if (this.anyDestinationFilterSelected()) {
          this.boundingBox = ''
        }
      } else {
        this.boundingBox = ''
      }
    } else {
      this.boundingBox = ''
    }

    const requestUrl = getUrlFromString(this.url, this.fixParamsBeforeUse({
      ...this.queryParams,
      ...this.offFilterParams,
      ...this.passThroughParams,
      ...removeUndefinedKeys(this.selectedValues),
      ...this.boundingBox,
      limit,
      ...{ isMap },
      ...{ isFirstLoad: this.isFirstLoad },
      ...{ clearFilters: options?.clearFilters }
    }))

    try {
      if (!this.filtersCollection) this.filtersCollection = this.initFiltersCollection()
      const newData = await fetchJsonData(requestUrl, { fullReferrerOnCrossOrigin: true })
      this._postRequestProcessing(newData, requestUrl)
      return this
    } catch (ex) {
      console.warn(ex)
      this.updateErrorMessagesVisibility(null, null, requestUrl)
      return null
    }
  }

  async fetchAndUpdateMap ({ resultType, markerId }) {
    this.updateSelectedFilters(false)
    this.setEnabledState(false)

    const limit = this.childComponents.map ? this.childComponents.map.getLimit() : this.offFilterParams.limit
    const offset = 0
    if (resultType === 'Country' || resultType === 'Region') {
      this.selectedValues[resultType] = [markerId]
      this.selectedValues.Destination = undefined
      this.boundingBox = ''
    } else {
      this.boundingBox = this.childComponents.map ? this.childComponents.map.getGeoBoundingBox() : undefined
      if (resultType !== 'Reload') {
        this.removeDestinationFilters()
      }
    }

    this.childComponents.map.clearMap()

    const requestUrl = getUrlFromString(this.url, this.fixParamsBeforeUse({
      ...this.queryParams,
      ...this.offFilterParams,
      limit,
      offset,
      ...removeUndefinedKeys(this.selectedValues),
      ...this.boundingBox,
      ...{ isMap: true },
      ...{ isFirstLoad: this.isFirstLoad }
    }))
    try {
      const newData = await fetchJsonData(requestUrl, { fullReferrerOnCrossOrigin: true })
      this._postRequestProcessing(newData, requestUrl)
      return this
    } catch (ex) {
      console.warn(ex)
      return null
    }
  }

  updateParticipantsFilterOnIsFlightOnly (newData) {
    const participantsSelectorApi = this._getParticipantsSelectorAPI()
    if (!participantsSelectorApi) return this
    participantsSelectorApi.isFlightOnly = newData.filters.some(filter => filter.type === 'IsFlightOnly')
    participantsSelectorApi.updateParticipantsFilter()
    return this
  }

  _postRequestProcessing (newData, requestUrl) {
    const part = newData.filters.find(filter => filter.type === FILTER_TYPES.PARTICIPANTS)
    if (part) { part.isMultiselectable = true }

    this.filtersCollection.reset(newData.filters, { silent: true })

    this.selectedValues = this.filtersCollection.getFiltersSelectedValuesObject()

    const totalResults = typeof newData.pagination === 'undefined' ? null : newData.pagination.totalResults
    const results = typeof newData.results === 'undefined' ? null : newData.results
    const availableResults = typeof newData.numberOfAvailableResults === 'undefined' ? null : newData.numberOfAvailableResults
    const accoTips = typeof newData.accoTips === 'undefined' ? null : newData.accoTips
    const pagination = typeof newData.pagination === 'undefined' ? null : newData.pagination
    const sortInfo = typeof newData.sortInfo === 'undefined' ? null : newData.sortInfo.property
    const selectionQueryString = typeof newData.selectionQueryString === 'undefined' ? null : newData.selectionQueryString
    const collectionTeaser = typeof newData.collectionTeaser === 'undefined' ? null : processCollectionTeaserData(newData.collectionTeaser)
    const geoBoundingFiltered = requestUrl.search.includes('Map%5B')

    this.initFilters()
      .updateFilterGroupsVisibility()
      .updateTotalResults(totalResults)
      .updateAvailableResults(availableResults)
      .updateSelectedFilters(true)
      .updateResults(results, accoTips, collectionTeaser)
      .updatePagination(pagination)
      .updateSort(sortInfo)
      .updateBrowserUrl(selectionQueryString)
      .updateErrorMessagesVisibility(results, totalResults, requestUrl)
      .updateWarningMessagesVisibility()
      .setEnabledState(true)
      .updateFiltersOnTop()
      .updateMapData(newData, geoBoundingFiltered)
      .updatePublicPrices(newData)
      .updateParticipantsFilterOnIsFlightOnly(newData)
    // Check for public price property and render value

    if (this.isFirstLoad) this.isFirstLoad = false
    this.events.emit('fetchAndUpdateFinished')
    eventBus.emit(SEARCH_API_RESPONSE_SUCCESS)
  }

  /**
   * Fix params to be sent
   *
   * - Process custom transformations if needed & available
   * - Arrayify values
   * - Encode values
   *
   * @param {Object} params
   * @returns {Object} fixed params
   */
  fixParamsBeforeUse (params = {}) {
    const paramsFixed = arrayifyObject(
      Object.entries(params)
        .reduce((newObj, [key, val]) => {
          newObj[key] = customFilterDataTransformations[key]
            ? customFilterDataTransformations[key](val)
            : val
          return newObj
        }, {})
    )
    return paramsFixed
  }

  /**
   * Returns the filtersData from URL params, if there's any
   * - Get the relevant params from the URL
   * - Derrayify data to a regular JS Object
   * - Addresses correct filter names problem, if needed
   *
   *
   * @returns {Object}
   */
  getSelectedValuesFromURL () {
    if (!document.location.search) return {}
    const relevantUrlParams = EXCLUDE_WHITELISTED_PARAMS(
      getSearchParamsFromUrl(document.location)
    )
    if (!relevantUrlParams || Object.keys(relevantUrlParams).length === 0) return {}

    return this.correctParams(relevantUrlParams)
  }

  correctParams (defaultParams) {
    const urlParams = derrayifyObject(defaultParams)

    // Return the final corrected object
    return Object.keys(urlParams).reduce((obj, key) => {
      obj[key] = urlParams[key]
      return obj
    }, {})
  }

  /*
  * -----------------------------------------------------
  * UI RELATED METHODS
  * -----------------------------------------------------
  */

  /**
   * Enable or Disable UI
   * - Filters element (wrapper)
   * - Main Body element (results, loading)
   *
   * @param {Boolean} enabled - The enable state
   *
   * @returns {Search} self instance
   */
  setEnabledState (enabled = true) {
    this.element.classList.toggle('is-loading', !enabled)

    if (this.elements.filtersWrapper) {
      this.elements.filtersWrapper.classList.toggle('is-disabled', !enabled)
    }
    if (this.elements.filtersTopWrapper) {
      this.elements.filtersTopWrapper.classList.toggle('is-disabled', !enabled)
    }

    if (this.elements.filtersMobileButton) {
      this.elements.filtersMobileButton[enabled ? 'removeAttribute' : 'setAttribute']('disabled', true)
    }

    if (this.childComponents.map) {
      this.childComponents.map.enableMap(enabled)
    }

    if (this.elements.showMapButtons) {
      this.elements.showMapButtons.forEach(element => {
        element[enabled ? 'removeAttribute' : 'setAttribute']('disabled', true)
      })
    }

    if (this.elements.pagination) {
      if (enabled) {
        this.elements.pagination.classList.add('is-loaded', !enabled)
      }
    }

    return this
  }

  /**
   * Update the visibility of filter groups in order to hide the ones that are empty (or display them again if needed)
   *
   * @returns {Search} self instance
   */
  updateFilterGroupsVisibility () {
    if (!this.elements.filterGroups) return this

    this.elements.filterGroups.forEach(group => {
      const groupIsEmpty = group.querySelectorAll(ELEMENT_QUERIES.filterElements(this.element.id) + ':not(.is-empty)').length === 0
      group.classList.toggle('is-empty', groupIsEmpty)
    })

    // Emit update event on all collapseElements
    this.childComponents.collapseAPIs.forEach(collapse => {
      collapse.update()
    })

    return this
  }

  /**
   * Update Total Results number
   *
   * @param {Number} amount - The total amount of given results
   *
   * @returns {Search} self instance
   */
  updateTotalResults (amount) {
    if (!amount) return this
    if (!this.elements.totalResults) return this

    const totalResultsPattern = this.elements.totalResults.getAttribute(ELEMENT_QUERIES.totalResultsAttr)
    this.elements.totalResults.innerText = totalResultsPattern ? totalResultsPattern.replace('{N}', amount.toString()) : ''

    return this
  }

  /**
   * Update available results number
   *
   * @param {Number} availableResultsAmount - The number of available results
   *
   * @returns {Search} self instance
   */
  updateAvailableResults (availableResultsAmount) {
    if (!availableResultsAmount) return this
    if (!this.elements.availableResults) return this

    const availableResultsPattern = this.elements.availableResults.getAttribute(ELEMENT_QUERIES.availableResultsAttr)
    this.elements.availableResults.innerText = availableResultsPattern ? availableResultsPattern.replace('{N}', availableResultsAmount.toString()) : ''

    return this
  }

  /**
   * Update results with public price value if the getSearchResponse api call has "publicPricesToken"
   *
   * @memberof Search
   *
   * @param {object} getSearchResponse response object
   *
   * @returns {Search} self instance
   */
  updatePublicPrices ({ publicPricesToken }) {
    if (!publicPricesToken || !this.publicPricesUrl) return this

    fetchJsonData({ href: `${this.publicPricesUrl}?contextitemid=${this.queryParams.contextitemid}&token=${publicPricesToken}` })
      .then(({ publicPrices }) => {
        if (!publicPrices) return
        publicPrices.forEach(({ skiPassPublicPrice, accommodationId }) => {
          if (skiPassPublicPrice) {
            // Select all in case there is a repeated acco id in the results list
            const searchResultElement = this.elements.results.querySelectorAll(`div[data-acco-id="${accommodationId}"]`)

            if (searchResultElement.length) {
              searchResultElement.forEach(el => {
                const priceElement = el.querySelector(ELEMENT_QUERIES.priceElement)
                const publicPriceDetailElement = elementFromString(publicPriceDetailTemplate({ publicPriceDetail: skiPassPublicPrice }))
                priceElement && priceElement.appendChild(publicPriceDetailElement)
              })
            }
          }
        })
      })

    return this
  }

  updateErrorMessagesVisibility (results, amount, requestUrl) {
    if (!this.elements.errorMessage || !this.elements.noResultsMessage) return this

    const error = (results === null || amount === null)
    const emptyResults = amount === 0

    if (this.elements.errorMessage) this.elements.errorMessage.classList.toggle('u-hidden', !error)
    if (this.elements.noResultsMessage) this.elements.noResultsMessage.classList.toggle('u-hidden', !emptyResults)
    if (this.elements.results) this.elements.results.classList.toggle('u-hidden', error || emptyResults)
    if (this.elements.pagination) this.elements.pagination.classList.toggle('u-hidden', error || emptyResults)
    if (this.elements.selectedFiltersClear) this.elements.selectedFiltersClear.classList.toggle('u-hidden', error)
    if (this.elements.totalResults) this.elements.totalResults.classList.toggle('u-hidden', error)
    if (this.elements.availableResults) this.elements.availableResults.classList.toggle('u-hidden', error)

    if (this.elements.sortElement) this.elements.sortElement.classList.toggle('is-disabled', error)
    if (this.elements.filtersWrapper) this.elements.filtersWrapper.classList.toggle('is-disabled', error)
    if (this.elements.selectedFilters) this.elements.selectedFilters.classList.toggle('is-disabled', error)

    this.element.classList.toggle('is-loading', !error && !emptyResults)

    if (emptyResults) {
      if (this.elements.totalResults) {
        const totalResultsPattern = this.elements.totalResults.getAttribute(ELEMENT_QUERIES.totalResultsAttr)
        this.elements.totalResults.innerText = totalResultsPattern ? totalResultsPattern.replace('{N}', amount.toString()) : ''
      }

      if (this.elements.availableResults) {
        this.elements.availableResults.innerText = ''
      }
    }
    if (error || emptyResults) {
      this.events.emit(searchEvents.SEARCH_ERROR, { search: requestUrl.search, isError: error })
    }

    return this
  }

  updateWarningMessagesVisibility () {
    if (this.elements.messageWrapper) {
      const anyRoomLengthMoreThanThreshold = this.participantsSelectorAPI.getParticipantsData().map(room => room.length).some((roomLength) => roomLength > RoomLengthThreshold)
      const message = this.elements.messageWrapper.getAttribute(ELEMENT_QUERIES.participantsWarningMessageAttr)
      const title = this.elements.messageWrapper.getAttribute(ELEMENT_QUERIES.participantsWarningTitleAttr)

      if (anyRoomLengthMoreThanThreshold && message) {
        this.elements.messageWrapper.innerHTML = SearchMessageTemplate({ title, text: message })
      } else {
        this.elements.messageWrapper.innerHTML = ''
      }
    }

    return this
  }

  /**
   * Update Selected Filters
   *
   * @returns {Search} self instance
   */
  updateSelectedFilters (isAfterFetch) {
    if (!this.elements.selectedFiltersChips) return this

    if (!isAfterFetch) {
      this._deselectDatesFilters()
    }

    // Get the data to be displayed
    this.selectedFiltersData = this._getSelectedFiltersData()

    // Remove filters on top to avoid redundant filter chips
    const filtersOnTopNames = this._getFiltersOnTopNames()
    const sidebarFilters = this.selectedFiltersData.filter(filter => !filtersOnTopNames.includes(filter.type))

    if (sidebarFilters.length) {
      // Append the chips to the element

      const destinationChipsTemplates = this.getDestinationFilterChips()

      const selectedFiltersCipsTemplates = sidebarFilters.filter(filter => filter.type !== FILTER_TYPES.DESTINATION)
        .map(filter => customChipsTextTransformations[filter.type]
          ? ChipTemplate({
            text: customChipsTextTransformations[filter.type](this, filter),
            value: `${filter.type}||||all`,
            highlighted: !filter.isMandatory,
            mandatory: filter.isMandatory,
            removable: !filter.isMandatory,
            variant: filter.isMandatory ? 'dark' : ''
          })
          : filter.values.map((value, i) => ChipTemplate({
            text: filter.captions[i],
            value: `${filter.type}||||${value}`,
            highlighted: !filter.isMandatory,
            mandatory: filter.isMandatory,
            removable: !filter.isMandatory,
            variant: filter.isMandatory ? 'dark' : ''
          })).join('')
        ).join('') + destinationChipsTemplates + this.clearSelectedFiltersTemplate()

      this.elements.selectedFiltersChips.innerHTML = selectedFiltersCipsTemplates
      if (this.elements.selectedFiltersClone) this.elements.selectedFiltersClone.innerHTML = selectedFiltersCipsTemplates

      this.element.querySelectorAll(ELEMENT_QUERIES.selectedFiltersClear).forEach(el => el.addEventListener('click', (ev) => this.onClickClearSelectedFilters(ev)))
    } else {
      this.elements.selectedFiltersChips.innerHTML = ''
    }

    this.checkIfSelectedFiltersIsEmpty(sidebarFilters)
    // Add or remove the is-empty class accordingly (show/hide)
    this.elements.selectedFilters.classList.toggle('is-empty', !this.selectedFiltersData.length)
    if (this.elements.selectedFiltersClone) this.elements.selectedFiltersClone.classList.toggle('is-empty', !this.selectedFiltersData.length)

    if (isAfterFetch) {
      const selectedFiltersApplied = this.selectedFiltersData
      if (this.selectedValues.Participants) {
        selectedFiltersApplied.push({
          type: FILTER_TYPES.PARTICIPANTS,
          values: this.selectedValues.Participants,
          captions: this.getParticipantsAgeProfiles()
        })
      }
      this.events.emit(searchEvents.SEARCH_FILTERS_APPLIED, selectedFiltersApplied)
    }
    return this
  }

  /**
   * Get destination filter chips templates
   *
   */
  getDestinationFilterChips () {
    const destinationFilter = this.getDestinationFilter()
    let destinationChips = ''
    if (destinationFilter?.[ELEMENT_QUERIES.destinationMultipleFilter]) {
      destinationChips = destinationFilter[ELEMENT_QUERIES.destinationMultipleFilter].getChipsElements()
    }

    return destinationChips
  }

  /**
   * Get destination filter element
   *
   */
  getDestinationFilter () {
    return [...this.elements.filters].filter(el => el.getAttribute(ELEMENT_QUERIES.filterNameAttr) === FILTER_TYPES.DESTINATION)[0]
  }

  /**
   * Check if remaining chip is mandatory to hide or show remove chips link
   *
   */
  checkIfSelectedFiltersIsEmpty (selectedFiltersData) {
    this.element.querySelectorAll(ELEMENT_QUERIES.selectedFiltersClear).forEach(el => {
      selectedFiltersData.length === 1
        ? el.classList[selectedFiltersData[0].isMandatory ? 'add' : 'remove']('hide')
        : el.classList.remove('hide')
    })
  }

  /**
   * Check if the dates filter that influence others need to be deselected
   *
   */
  _deselectDatesFilters () {
    if (!this.selectedFiltersData) {
      return null
    }

    const newFiltersData = this._getSelectedFiltersData(false)

    const previousLastMinute = JSON.stringify(this.selectedFiltersData.filter(filter => filter.type === FILTER_TYPES.LAST_MINUTE))
    const currentLastMinute = JSON.stringify(newFiltersData.filter(filter => filter.type === FILTER_TYPES.LAST_MINUTE))

    const previousDepartureDate = JSON.stringify(this.selectedFiltersData.filter(filter => filter.type === FILTER_TYPES.DEPARTURE_DATE))
    const currentDepartureDate = JSON.stringify(newFiltersData.filter(filter => filter.type === FILTER_TYPES.DEPARTURE_DATE))

    if (previousLastMinute !== currentLastMinute) {
      if (this.selectedValues.DepartureDate) {
        this.filtersCollection.findWhere('type', FILTER_TYPES.DEPARTURE_DATE).clearSelection({ silent: true })
        delete this.selectedValues.DepartureDate
      }
    } else if (previousDepartureDate !== currentDepartureDate) {
      if (this.selectedValues.Lastminute) {
        this.filtersCollection.findWhere('type', FILTER_TYPES.LAST_MINUTE).clearSelection({ silent: true })
        delete this.selectedValues.Lastminute
      }
    }
  }

  _getSelectedFiltersData (updateDestinationFilter = true) {
    this.elements.filters = document.querySelectorAll(ELEMENT_QUERIES.filterElements(this.element.id))
    // Get the current shown filter types as they are displayed (same order)
    const currentShownFilters = [...this.elements.filters].map(el => el.getAttribute(ELEMENT_QUERIES.filterNameAttr))

    // Get the data to be displayed
    const selectedFiltersData = currentShownFilters
      .reduce((arr, filterType) => {
        // Omit hidden filter types
        if (HIDDEN_FILTER_TYPES.includes(filterType)) return arr
        const filterModel = this.filtersCollection
          .findWhere('type', filterType)
        let selectedInThisIteration = false

        // Set destination filter as selected if has selected values
        if (DESTINATION_FILTER_TYPES.includes(filterType) && filterModel && updateDestinationFilter) {
          selectedInThisIteration = !this._getSelectedDestinationFilterByType(filterType).selected &&
            filterModel.hasSelectedValues()
          this._getSelectedDestinationFilterByType(filterType).selected = filterModel.hasSelectedValues()
        }

        // Omit unknown & empty filters (no selected values)
        if (!filterModel || !filterModel.getSelectedValues().length) return arr

        // Remove values for a destination filter if it hasn't been selected in this call of updateSelectedFilters
        // and the parent filter has no values
        if (DESTINATION_FILTER_TYPES.includes(filterType) && updateDestinationFilter) {
          const parentFilterModel = this.filtersCollection.findWhere('type', filterModel.attributes.parentFilterType)
          if (parentFilterModel && !parentFilterModel.getSelectedValues().length && !selectedInThisIteration) {
            filterModel.clearSelection({ silent: true })
            this.selectedValues[filterType] = undefined
            this._getSelectedDestinationFilterByType(filterType).selected = false
            return arr
          }
        }

        // Push the data
        arr.push({
          type: filterType,
          values: filterModel.getSelectedValues(),
          captions: filterModel
            .getSelectedModels()
            .map(valueModel => valueModel.getAttribute('caption')),
          isMandatory: filterModel.isMandatory()
        })
        return arr
      }, [])
    return selectedFiltersData
  }

  _getSelectedDestinationFilterByType (filterType) {
    return this.destinationFilters.find(v => v.name === filterType)
  }

  /**
   * Update Results
   *
   * @param {SearchResultData[]} [freshResults] - The filters data
   *
   * @returns {Search} self instance
   */
  updateResults (freshResults = [], accoTips = [], collectionTeaser = null) {
    // Destroy components on results
    Component.destroyDocumentComponents(this.elements.results)

    const resultsElements = freshResults.map(result => {
      // Prepare a better urgency messages
      const urgencyMessages = []
      if (result.amountOfBookingsText) urgencyMessages.push(result.amountOfBookingsText)
      if (result.lastBookingDateText) urgencyMessages.push(result.lastBookingDateText)

      const campaignUrl = result.campaign ? `${this.campaignDetailsUrl}?campaignid=${result.campaign.id}&contextItemId=${this.queryParams.contextitemid}` : ''
      // Return rendered result
      const parentContextItemId = this.queryParams.contextitemid
      const template = this.toggleCardTemplate
        ? ProductCardTemplate({ ...result, urgencyMessages, campaignUrl, parentContextItemId }, this.locales)
        : SearchResultTemplate({ ...result, urgencyMessages, campaignUrl, parentContextItemId }, this.locales, this.useProductCardFooter)
      return template
    })

    // If there's collection teaser data, it will be pre-prended to the results.
    if (collectionTeaser) {
      resultsElements.unshift(CollectionTeaserTemplate(collectionTeaser))
    }

    this.events.emit(searchEvents.SEARCH_RESULTS, freshResults)

    // Update HTML with results
    this.elements.results.innerHTML = this.generateResultsHTML(accoTips, resultsElements, defaultAccoTipsPosition)

    window.dispatchEvent(this.resultsRenderedEvent)

    bindClickEventToResults(freshResults, this._getLinksForEachResult(), this.onClickSearchResult, this.events)

    const accoTipsElement = this.elements.results.querySelector(`.${ELEMENT_QUERIES.accoTips}`)

    if (accoTips && accoTips.length > 0) {
      accoTips = accoTips.map(item => {
        return {
          ...item,
          image: Array.isArray(item.images) && item.images.length > 0 ? item.images[0] : '',
          isAccoTip: true
        }
      })

      const accoTipsApi = new AccoLister(accoTipsElement,
        {
          id: this.element.id,
          variant: 'tips',
          packagesPerSlide: defaultAccoTipsPerSlide,
          acmModalId: 'w-search__modal',
          originList: 'acco-tips',
          priceLabelText: this.locales.priceLegend,
          items: accoTips
        })

      this.childComponents = {
        ...this.childComponents,
        accoTipsAPI: accoTipsApi
      }
    }

    Img.createInstancesOnDocument(this.elements.results)
    Component.initDocumentComponentsFromAPI(this.elements.results)
    Component.initComponentActionElements(this.elements.results)

    this.subscribeToFavoritesBtnEvents(freshResults)

    return this
  }

  _getLinksForEachResult () {
    const selector = this.toggleCardTemplate ? '.c-product-card' : '.c-search-result'
    const resultElements = Array.from(this.elements.results.querySelectorAll(selector))
    return resultElements.map(result => Array.from(result.querySelectorAll(`${selector}__link`)))
  }

  subscribeToFavoritesBtnEvents (results) {
    const btns = this.elements.results.querySelectorAll(`.${ELEMENT_QUERIES.favoriteBtn}`)
    btns.forEach((btn, index) => {
      const favoriteBtnApi = btn[ELEMENT_QUERIES.favoriteBtn]
      const getResult = (id) => results.find(result => `${result.id}` === id)

      favoriteBtnApi.events.on(favoriteEvents.FAVORITE_MODAL_OPEN, (data) => {
        const resultData = getResult(data.id)
        this.events.emit(favoriteEvents.FAVORITE_MODAL_OPEN, { ...resultData, position: index })
      })
      favoriteBtnApi.events.on(favoriteEvents.FAVORITE_MODAL_CLOSE, (data) => {
        const resultData = getResult(data.id)
        this.events.emit(favoriteEvents.FAVORITE_MODAL_CLOSE, { ...resultData, position: index })
      })
      favoriteBtnApi.events.on(favoriteEvents.FAVORITE_SIGNUP, (data) => {
        const resultData = getResult(data.id)
        this.events.emit(favoriteEvents.FAVORITE_SIGNUP, { ...resultData, position: index })
      })
      favoriteBtnApi.events.on(favoriteEvents.FAVORITE_LOGIN, (data) => {
        const resultData = getResult(data.id)
        this.events.emit(favoriteEvents.FAVORITE_LOGIN, { ...resultData, position: index })
      })
      favoriteBtnApi.events.on(favoriteEvents.FAVORITE_ADD, (data) => {
        const resultData = getResult(data.id)
        this.events.emit(favoriteEvents.FAVORITE_ADD, { ...resultData, position: index })
      })
      favoriteBtnApi.events.on(favoriteEvents.FAVORITE_REMOVE, (data) => {
        const resultData = getResult(data.id)
        this.events.emit(favoriteEvents.FAVORITE_REMOVE, { ...resultData, position: index })
      })
    })
  }

  onClickSearchResult (ev, result, events) {
    events.emit(searchEvents.SEARCH_RESULT_CLICK, { data: { ...result }, event: ev })
  }

  // Insert the 'accoTips' html in 'position' between the 'results'
  generateResultsHTML (accoTips = [], resultsElements = [], position = 0) {
    if (!accoTips || accoTips.length === 0) {
      return resultsElements.join('')
    } else {
      return [...resultsElements.slice(0, position), this.insertAccoTipsWrapperHTML(), ...resultsElements.slice(position, resultsElements.length)].join('')
    }
  }

  insertAccoTipsWrapperHTML () {
    const title = this.element.getAttribute(ELEMENT_QUERIES.accoTipsTitleAttr)
    return `
<div class='${ELEMENT_QUERIES.accoTips}' data-track='track'>
    <p class="acco_tips-header">${title || ''}</p>
    <div class="w-acco-lister__results"></div>
</div>`
  }

  /**
   * Update Pagination
   *
   * @param {Object} pagination               - Pagination details as received from api
   * @param {Number} pagination.offset        - First result shown
   * @param {Number} pagination.limit         - Results per page
   * @param {Number} pagination.totalResults  - Results per page
   *
   * @returns {Search} self instance
   */
  updatePagination (pagination) {
    if (!pagination || !this.childComponents || !this.childComponents.pagination) return this

    this.pagination = pagination
    const totalPages = Math.ceil(pagination.totalResults / pagination.limit)
    const currentPage = (pagination.offset / pagination.limit) + 1
    this.childComponents.pagination.setProps({ currentPage, totalPages }, { silent: true })
    return this
  }

  /**
   * Update Sort (by popularity, price...)
   *
   * @param {String} sortValue
   *
   * @returns {Search} self instance
   */
  updateSort (sortValue) {
    if (!sortValue) return this
    if (!this.childComponents.sort.length) return this

    this.childComponents.sort.forEach(element => {
      element.setProp('value', sortValue, { silent: true })
    })

    this.togglePopularityTooltip(sortValue)

    return this
  }

  /**
   * Update Browser's url with all the params excepting the implicit ones (config.hiddenParams)
   *
   * @param {String} searchString - Part of the search already created
   *
   * @returns {Search} self instance
   */
  updateBrowserUrl (searchString) {
    window.history.pushState(
      '',
      '',
      buildUrlWithParams(
        document.URL.split('?')[0],
        this.fixParamsBeforeUse({
          ...removeUndefinedKeys(this.selectedValues),
          ...this.offFilterParams,
          ...this.whiteListedParams,
          ...this.passThroughParams
        })
      ))
    return this
  }

  /*
  * -----------------------------------------------------
  * EVENTS RELATED METHODS
  * -----------------------------------------------------
  * */

  /**
   * Handled by any change on filters collection
   *
   * - Updates Selected Filters
   * - Updates with fresh data
   */
  onChangeFilters (eventArgs) {
    const clearFilters = eventArgs?.clearFilters || false

    this.updateSelectedFilters(false)
    this.fetchAndUpdate({ clearFilters })
    this.updateFiltersOnTopInfoElements()
  }

  selectedFiltersChipRemoval (selectedFilterChip, options = {}) {
    const [filterType, filterValue] = selectedFilterChip.getAttribute('data-value').split('||||')
    const filterCollectionType = this.filtersCollection.findWhere('type', filterType)

    if (filterType === FILTER_TYPES.destination) {
      const destinationFilterApi = this.getDestinationFilter()[ELEMENT_QUERIES.destinationMultipleFilter]
      destinationFilterApi.updateModelOnChipUpdate(filterValue)
    } else if (filterValue === 'all') {
      filterCollectionType.clearSelection(options)
    } else {
      // Safety check. An error occurred (see MN-4541) and cannot be reproduced
      // but just for extra safety a null/undefined check has been added.
      // Check improved due to error being affecting (see MN-4814).
      if (filterCollectionType?.values) {
        filterCollectionType.values
          .getModelByValue(filterValue)
          .setSelection(false, options)

        this.events.emit(searchEvents.SEARCH_REMOVE_SINGLE_CHIP_FILTER,
          {
            type: filterType,
            data: filterCollectionType.values
          })
      }
    }
  }

  /**
   * Handled by click on selectedFiltersElement
   *
   * - Checks which chip has triggered the event (if any)
   * - Remove the selected value from filters
   * - Destroy the chip
   */
  onClickSelectedFiltersChip (ev) {
    const selectedFilterChip = ev.target === this.elements.selectedFiltersChips
      ? undefined
      : ev.target.closest('.c-chip')
    if (!selectedFilterChip) return
    this.selectedFiltersChipRemoval(selectedFilterChip)
    selectedFilterChip.remove()
  }

  /**
   * Handled by click on clearSelectedFilters button
   *
   * - Remove every selected filter silently
   * - Trigger a change
   */
  onClickClearSelectedFilters (ev) {
    if (this.elements.selectedFiltersClone) {
      this.removeChips(this.elements.selectedFiltersClone)
    }
    this.removeChips(this.elements.selectedFiltersChips)
    // Preserve filter on top and mandatory filter values
    const filtersOnTopNames = this._getFiltersOnTopNames()
    const mandatoryFilterNames = this._getMandatoryFilterNames()
    const filtersToMaintain = [...filtersOnTopNames, ...mandatoryFilterNames]
    Object.keys(this.selectedValues).forEach(key => {
      if (!filtersToMaintain.includes(key)) delete this.selectedValues[key]
    })

    this.events.emit('change', { clearFilters: true })
  }

  removeChips (selectedChipsElement) {
    selectedChipsElement.querySelectorAll('.c-chip').forEach(selectedFilterChip => {
      if (!selectedFilterChip.classList.contains('c-chip--mandatory')) {
        this.selectedFiltersChipRemoval(selectedFilterChip, { silent: true })
        selectedFilterChip.remove()
      }
    })
  }

  /**
   * Filters On Top Collapse Methods
   *
   */
  onFilterOnTopCollapse (ev) {
    this.filtersOnTopCollapsed = !this.filtersOnTopCollapsed
    this.elements.filtersOnTopQSWrapper.classList.toggle('filters_on_top__collapse')
    this.elements.filtersOnTopCollapsedContainer.classList.toggle('filters_on_top__collapse')
    this.elements.filtersOnTopWrapper.classList.toggle('collapsed-top-filters')

    this.updateFiltersOnTopInfoElements()

    this.elements.filtersOnTopCollapseIcon.classList.add(this.filtersOnTopCollapsed ? 'm-icon--pencil' : 'm-icon--chevron')
    this.elements.filtersOnTopCollapseIcon.classList.remove(this.filtersOnTopCollapsed ? 'm-icon--chevron' : 'm-icon--pencil')
    this.elements.filtersOnTopCollapseText.innerText = this.filtersOnTopCollapsed ? this.modifyText : this.collapseText

    this.elements.filtersOnTopOverlay.classList[this.filtersOnTopCollapsed ? 'add' : 'remove']('open')
    this.elements.filtersOnTopCollapse.classList[this.filtersOnTopCollapsed ? 'add' : 'remove']('close')
  }

  updateFiltersOnTopInfoElements () {
    const templates = this.getTopFiltersDataTemplates()
    if (this.elements.filtersOnTop) this.elements.filtersOnTopCollapsedContainer.innerHTML = templates.join('')
    this.elements.topFiltersSummary.innerHTML = templates.join('')
  }

  getTopFiltersDataTemplates () {
    const selectedTopFiltersData = this._getSelectedFiltersTopData()
    const templates = []

    if (this.elements.filtersOnTopParticipants) {
      const participantsText = this.elements.filtersOnTopParticipants.querySelector('input').value
      const particpantsIcon = this.elements.filtersOnTopParticipants.querySelector('[class*="icon"]')
      particpantsIcon.classList.add('w-search__top-filter__minimal--icon')
      templates.push(this.collapsedFilterTemplate({ icon: particpantsIcon.outerHTML, content: participantsText }))
    }

    selectedTopFiltersData.forEach(data => {
      const icon = document.querySelector(`[data-w-filters__name="${data.type}"]`).querySelector('.c-textbox__icon, .c-dropdown__icon')
      icon.classList.add('w-search__top-filter__minimal--icon')

      if (data.type === FILTER_TYPES.DEPARTURE_DATE) {
        const text = customChipsTextTransformations[data.type](this, data)
        templates.push(this.collapsedFilterTemplate({ icon: icon.outerHTML, content: text }))
      } else if (data.type === FILTER_TYPES.DESTINATION) {
        const text = this.getDestinationFilter()[ELEMENT_QUERIES.destinationMultipleFilter]
          .getSelectedOptions()
          .map(value => value.attributes.caption).join(', ')

        templates.push(this.collapsedFilterTemplate({ icon: icon.outerHTML, content: text }))
      } else {
        const newIcon = icon.cloneNode(true)

        if (data.type === FILTER_TYPES.TRANSPORT_TYPE && icon.classList.contains('c-dropdown__arrow')) {
          newIcon.classList.remove('c-dropdown__arrow')
          newIcon.classList.remove('m-icon--chevron-down')
          newIcon.classList.remove('m-icon--chevron-up')
          newIcon.classList.add('m-icon--car')
        }
        templates.push(this.collapsedFilterTemplate({ icon: newIcon.outerHTML, content: data.captions.join(', ') }))
      }
    })
    return templates
  }

  updateFiltersOnTop () {
    if (!this.elements.filtersOnTop) return this
    this.collapseText = this.elements.filtersOnTop.getAttribute('filters-on-top__collapse-text') || ''
    this.modifyText = this.elements.filtersOnTop.getAttribute('filters-on-top__modify-text') || ''
    this.elements.filtersOnTopCollapseText.innerText = this.filtersOnTopCollapsed ? this.modifyText : this.collapseText

    this.toggleFilterOnTopLoader(false)

    return this
  }

  toggleFilterOnTopLoader (open) {
    if (!this.elements.filtersOnTop) return

    this.elements.filtersOnTopLoader.classList[open ? 'add' : 'remove']('open')
    this.elements.filtersOnTopWrapper.classList[open ? 'add' : 'remove']('close')

    // Remove search page padding
    if (document) {
      const searchPage = document.querySelector('.t-searchpage')
      if (searchPage) searchPage.querySelector('.l-site-main').classList.add('w-search__top-filters__body')
    }
  }

  collapsedFilterTemplate (filter) {
    return `<div class="w-search__top-filter__minimal">
      ${filter.icon}
      <span class="w-search__top-filter__minimal--text">${filter.content}</span>
    </div>`
  }

  clearSelectedFiltersTemplate () {
    const text = this.element.querySelector('.w-search__selected-filters').getAttribute('data-w-search__clear-filters-text') || ''
    return `<div class="w-search__clear-wrapper">
      ${BtnTemplate({ text, variant: 'flat', extraClasses: 'w-search__clear' })}
    </div>`
  }

  _getSelectedFiltersTopData () {
    const selectedFiltersData = this._getSelectedFiltersData(false)
    const filtersOnTopNames = this._getFiltersOnTopNames()

    // Compare selecterdFiltersData against filters to get the used filters
    const selectedTopFiltersData = selectedFiltersData.filter(filter => filtersOnTopNames.some(filterName => filter.type === filterName))
    return selectedTopFiltersData
  }

  _getFiltersOnTopNames () {
    if (this.elements.filtersOnTop) {
      const filters = this.elements.filtersOnTop.querySelectorAll('fieldset')
      return [...filters].map(filter => filter.getAttribute('data-w-filters__name')).filter(f => f !== null)
    }
    return []
  }

  _getMandatoryFilterNames () {
    return this.filtersCollection.models.filter(f => f.getAttribute('isMandatory') === true).map(f => f.getAttribute('type'))
  }

  // Maps

  updateMapData (data, geoBoundingFiltered) {
    if (this.childComponents.map) {
      this.childComponents.map.setMapData(data, this.filtersCollection, this.selectedValues, geoBoundingFiltered)
    }
    return this
  }

  removeDestinationFilter (filterType) {
    const filterModel = this.filtersCollection
      .findWhere('type', filterType)
    if (!filterModel || !filterModel.getSelectedValues().length) return
    filterModel.clearSelection({ silent: true })
    this.selectedValues[filterType] = undefined
    this._getSelectedDestinationFilterByType(filterType).selected = false
  }

  removeDestinationFilters () {
    this.removeDestinationFilter(DESTINATION_FILTER_TYPES.COUNTRY)
    this.removeDestinationFilter(DESTINATION_FILTER_TYPES.REGION)
    this.removeDestinationFilter(DESTINATION_FILTER_TYPES.SUBREGION)
    this.removeDestinationFilter(DESTINATION_FILTER_TYPES.CITY)
    this.removeDestinationFilter(DESTINATION_FILTER_TYPES.DESTINATION)
  }

  anyDestinationFilterSelected () {
    let anySelected = false
    DESTINATION_FILTER_TYPES.forEach(f => { anySelected = this._getSelectedDestinationFilterByType(f).selected || anySelected })
    return anySelected
  }

  showChipsToFiltersComponent (show) {
    this.elements.selectedFiltersContainer.classList[show ? 'remove' : 'add']('hide')
  }

  insertSelectedFiltersContainerToFiltersComponent () {
    this.elements.selectedFiltersContainer = elementFromString(`
    <div class="w-search__selected-filters-container hide">
      <div class="w-search__top-filters-summary"></div>
    </div>`)

    this.elements.searchFiltersBodyContent.insertBefore(this.elements.selectedFiltersContainer, this.elements.searchFiltersBodyContent.children[0])
    if (this.elements.selectedFilters) {
      this.elements.selectedFiltersClone = this.elements.selectedFilters.cloneNode(true)
      this.elements.selectedFiltersClone.classList.remove('is-empty')
      this.elements.selectedFiltersContainer.appendChild(this.elements.selectedFiltersClone)
      this.elements.selectedFiltersClone.addEventListener('click', (ev) => this.onClickSelectedFiltersChip(ev))
    }
    this.elements.topFiltersSummary = this.element.querySelector(ELEMENT_QUERIES.topFiltersSummary)
  }

  addShowHideFiltersSummaryEvents () {
    this.elements.filtersWrapper['c-side-drawer'].events.on('opened', () => this.showChipsToFiltersComponent(true))
    this.elements.filtersWrapper['c-side-drawer'].events.on('closed', () => this.showChipsToFiltersComponent(false))
    if (!this.childComponents.map) return
    this.childComponents.map.modalAPI.events.on('opening', () => this.showChipsToFiltersComponent(true))
    this.childComponents.map.modalAPI.events.on('close', () => this.showChipsToFiltersComponent(false))
  }

  togglePopularityTooltip (value) {
    const isHidden = value !== 'Popularity'
    this.elements.popularityTooltips.forEach(tooltip => {
      tooltip.classList.toggle('u-hidden', isHidden)
    })
  }
}

const options = buildSearchWidgetOptions()
registerWidget(Search, WIDGET_API, options)
