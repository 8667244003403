import DatesFilter from '../dates-filter/main'
import DropdownFilter from '../dropdown-filter/main'
import DropdownMultipleFilter from '../dropdown-multiple-filter/main'
import DropdownDynamicFilter from '../dropdown-dynamic-filter/main'
import DestinationFilter from '../destination-filter/main'
import ChoiceListFilter from '../choice-list-filter/main'
import PriceFilter from '../price-filter/main'
import ParticipantsFilter from '../participants-filter/main'
import AirportFilter from '../airport-filter/main'
import { FILTER_TYPES } from '../../../js/data/filters/config'

export const WIDGET_API = 'w-search'

export const COLLAPSE_API = 'c-collapse'

export const MAP_API = 'w-search-map'

export const ELEMENT_QUERIES = {
  urlAttr: `data-${WIDGET_API}__url`,
  campaignDetailsUrl: `data-${WIDGET_API}__campaign-details__url`,
  publicPricesUrl: `data-${WIDGET_API}__public-prices__url`,
  defaultQueryStringAttr: `data-${WIDGET_API}__default-querystring`,
  resultOnlyAttr: `data-${WIDGET_API}__results-only`,
  filtersElement: `.${WIDGET_API}__filters`,
  filtersTopElement: `.${WIDGET_API}__top-filters`,
  extraParams: 'input[type="hidden"]',
  sortElement: `.${WIDGET_API}__sort`,
  filterElements: (id) => `[data-w-filters__id="${id}"]`,
  filterViewAttr: 'data-w-filters__view',
  filterNameAttr: 'data-w-filters__name',
  filterGroups: `.${WIDGET_API}__box`,
  selectedFilters: `.${WIDGET_API}__selected-filters`,
  selectedFiltersChips: `.${WIDGET_API}__selected-chips`,
  selectedFiltersClear: `.${WIDGET_API}__clear`,
  totalResults: `[data-${WIDGET_API}__total-results]`,
  totalResultsAttr: `data-${WIDGET_API}__total-results`,
  availableResults: `[data-${WIDGET_API}__available-results]`,
  availableResultsAttr: `data-${WIDGET_API}__available-results`,
  resultsElement: `.${WIDGET_API}__results`,
  paginationElement: `.${WIDGET_API}__pagination`,
  errorMessage: `.${WIDGET_API}__results-error-message`,
  noResultsMessage: `.${WIDGET_API}__results-no-results-message`,
  clearFiltersTrigger: `[data-${WIDGET_API}__action="clear-filters"]`,
  collapseElements: `.${WIDGET_API}__box-content [data-js-component="${COLLAPSE_API}"]`,
  trackAttr: 'data-track',
  accoTipsTitleAttr: `data-${WIDGET_API}__acco-tips-title`,
  accoTips: `${WIDGET_API}-acco-tips`,
  filtersOnTopCollapse: `.${WIDGET_API}__top-filters__collapse--container`,
  mapElement: `[data-js-widget="${MAP_API}"]`,
  filtersMobileButton: '.w-search__filters-action-btn',
  showMapButton: '.w-search__map-action-btn',
  searchFiltersBodyContent: '.c-side-drawer__body .c-side-drawer__body-content',
  selectedFiltersContainer: `.${WIDGET_API}__selected-filters-container`,
  topFiltersSummary: `.${WIDGET_API}__top-filters-summary`,
  favCleanerButton: '.c-favorite-cleaner',
  messageWrapper: `.${WIDGET_API}__header-message-wrapper`,
  participantsWarningTitleAttr: `data-${WIDGET_API}-participants-warning-title`,
  participantsWarningMessageAttr: `data-${WIDGET_API}-participants-warning-message`,
  popularityTooltip: '.w-search__popularity-tooltip',
  toggleProductCard: 'data-toggle-product-card',
  destinationMultipleFilter: 'w-destination-multiple-filter',
  favoriteBtn: 'c-favorite-btn',
  useProductCardFooter: 'data-use-product-card-footer',
  priceElement: '.c-price'
}

export const FILTERS_ON_TOP = `${WIDGET_API}__top-filters`

export const FILTERS_ON_TOP_ELEMENT_QUERIES = {
  filtersOnTop: `.${FILTERS_ON_TOP}`,
  filtersOnTopQSWrapper: '.w-quick-search__wrapper',
  filtersOnTopCollapsedContainer: `.${FILTERS_ON_TOP}__collapsed--container`,
  filtersOnTopWrapper: `.${FILTERS_ON_TOP}__wrapper`,
  filtersOnTopParticipants: '[data-w-filters__view="participants"]',
  filtersOnTopMinimalIcon: `.${FILTERS_ON_TOP}__minimal--icon`,
  filtersOnTopMinimalText: `.${FILTERS_ON_TOP}__minimal--text`,
  filtersOnTopCollapseIcon: `.${FILTERS_ON_TOP}__collapse--icon`,
  filtersOnTopCollapseText: `.${FILTERS_ON_TOP}__collapse--text`,
  filtersOnTopOverlay: `.${FILTERS_ON_TOP}__overlay`,
  filtersOnTopLoader: `.${FILTERS_ON_TOP}__loader`

}

// Definition of available FilterViews
export const KNOWN_FILTER_VIEWS = {
  dates: DatesFilter,
  dropdown: DropdownFilter,
  'dropdown-multiple': DropdownMultipleFilter,
  'choice-list': ChoiceListFilter,
  'choice-list-single': ChoiceListFilter,
  range: PriceFilter,
  participants: ParticipantsFilter,
  airport: AirportFilter,
  destination: DestinationFilter,
  'dropdown-dynamic': DropdownDynamicFilter
}

// Definition of filter types to hide on selected filters
export const HIDDEN_FILTER_TYPES = [
  FILTER_TYPES.PARTICIPANTS
]

export function getSearchParamsFromUrl (url = document.location, useUndefinedValue = false) {
  const targetUrl = url instanceof (window.URL)
    ? url
    : new window.URL(url)
  return targetUrl.search
    ? [...targetUrl.searchParams.entries()].reduce((obj, [key, val]) => ({ ...obj, [key]: val === '' && useUndefinedValue ? undefined : val }), {})
    : undefined
}

// Sanitize QueryString if it seems a valid querystring and doesn't start with '?'
export function sanitizeQueryString (defaultQueryString) {
  const splitQueryString = defaultQueryString
    ? defaultQueryString.split('?')
    : undefined

  return splitQueryString
    ? splitQueryString.length > 1
      ? splitQueryString[1]
      : splitQueryString[0]
    : undefined
}

/**
 *
 *
 * @export
 * @param {*} defaultQueryParams
 * @param {*} queryParams
 * @return {*} queryParams {Object}
 */
export function removeDefaultQueryParams (defaultQueryParams, queryParams) {
  Object.keys(defaultQueryParams).forEach(param => {
    if (param.includes('[')) {
      const key = param.split('[')[0]
      if (key in queryParams) {
        queryParams[key] = queryParams[key].filter(v => v !== defaultQueryParams[param])
        if (!queryParams[key].length) delete queryParams[key]
      }
    } else if (param in queryParams) delete queryParams[param]
  })
  return queryParams
}
