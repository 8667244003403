import { TitleTemplate } from '../title/c-title.template'
import { defaultBoxData, BoxTemplate } from '../box/c-box.template'

export const defaultSearchMessageData = {
  id: '',
  isHidden: true,
  extraClasses: '',
  variant: 'flat',
  attributes: '',
  type: 'info'
}

export const SearchMessageTemplate = (d, staticTexts) => {
  d = { ...defaultSearchMessageData, ...d }
  return `
<div class="c-search-message" ${d.isHidden ? 'is-hidden' : ''}">
  <div class="sp-stack-children--medium">
    ${BoxTemplate({
      ...defaultBoxData,
        variant: d.variant,
      extraClasses: 'cs-state-' + d.type + ' cs-state-' + d.type + '--light c-search-message__box ',
      html: `
        ${d.title
        ? `${TitleTemplate({
          text: d.title,
          size: 'tiny',
          variant: '',
          extraClasses: 'c-search-message__title'
        })}`
        : ''}
        <div class="c-search-message__text">${d.text}</div>`
    })}
  </div>
</div>



`
}
