import { TitleTemplate } from '../title/c-title.template'
import { LabelTemplate } from '../label/c-label.template'
import { ImgTemplate } from '../img/c-img.template'
import { BulletListTemplate } from '../bullet-list/c-bullet-list.template'

import { getTokenClass, getToken } from '../../../js/helpers/tokens'
import { getCollectionIcon } from '../../../js/helpers/collection-mapper'

// Import tokens.
const tokensShared = require('./data/c-collection-teaser__tokens.json')['c-collection-teaser']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/components/collection-teaser/data/c-collection-teaser__tokens.json`)[ // eslint-disable-line
      'c-collection-teaser'
    ]
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

// Import cross-component shared tokens.
const tokensCollections = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/data/ds-tokens-shared.json`)['collections']; // eslint-disable-line
  } catch (error) {
    return {}
  }
})()

/**
 * The CollectionTeaserData contains all the data needed to hydrate a CollectionTeaser view.
 *
 * @typedef {Object}       CollectionTeaserData
 *
 * @property {String}      Collection - Collection name.
 * @property {Boolean}     CollectionLabelHidden - Collection label should be hidden.
 * @property {ImgData}     Image      - The associated image block.
 * @property {String}      Title      - The title of the collection teaser.
 * @property {Array}       Usps       - List of collection USPs.
 * @property {String}      Html       - HTML snippet
 * @property {String}      [Variant]  - The variant of the component.
 */
export const defaultCollectionTeaserData = {
  collection: '',
  collectionLabelHidden: true,
  image: {},
  title: '',
  usps: [],
  html: '',
  variant: ''
}

export const CollectionTeaserTemplate = d => {
  d = { ...defaultCollectionTeaserData, ...d }
  const collectionId = d.collection ? d.collection.id : ''
  const collectionToken = getToken('collection', collectionId, tokensCollections) || undefined

  if (!collectionToken) return ''

  return `
<div class="c-collection-teaser ${getTokenClass('variant', d.variant || 'default', tokens)}">
  ${
    !d.collectionLabelHidden && d.collection
      ? LabelTemplate({
          text: d.collection.text,
          collection: d.collection.id,
          variant: 'large',
          icon: getCollectionIcon(d.collection)
        })
      : ''
  }

  <div class="c-collection-teaser__container">
    <div class="c-collection-teaser__container-top">
      <div class="c-collection-teaser__img">
        ${ImgTemplate(d.image)}
      </div>

      <div class="c-collection-teaser__description ${
        getToken('collection', collectionId, tokensCollections).colorScheme
      }">
        ${
          d.title
            ? `${TitleTemplate({
                text: d.title,
                variant: '',
                extraClasses: 'c-collection-teaser__title'
              })}`
            : ''
        }

        <div class="c-collection-teaser__container-main">
          ${d.html}
        </div>
      </div>
    </div>

  ${
    d.usps && d.usps.length
      ? `<div class="c-collection-teaser__container-bottom">
        ${BulletListTemplate({
          items: d.usps.map(usp => ({ text: usp })),
          extraClasses: 'c-collection-teaser__usps'
        })}
      </div>`
      : ''
  }
  </div>
</div>
`
}
