/**
 * ---------------
 *     README
 * ---------------
 * ⚠ About this ⚠
 *
 * The reason to have this file here, is because the differences on data models in between
 * Sitecore and Design System components. The responsibility of this file is, given an API
 * model, obtain a ready to render model.
 *
 */

import { getProcessedCollectionDataFromItem } from '../../../js/helpers/collection-mapper'

const DEFAULT_OPTIONS = {
  imageSettings: {
    sync: true,
    ratio: '16:9'
  },
  variant: 'large'
}

export const processCollectionTeaserData = (item) => ({
  collection: getProcessedCollectionDataFromItem(item),
  image: {
    ...DEFAULT_OPTIONS.imageSettings,
    resolvedSrc: item.image,
    title: item.title
  },
  collectionLabelHidden: false,
  title: item.title,
  html: item.text,
  usps: item.usps,
  variant: DEFAULT_OPTIONS.variant
})
