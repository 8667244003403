import { RatingTemplate } from '../../components/rating/c-rating.template'
import { getData } from '../../../js/document/html-helper'
import { capitalizeString } from '../../../js/helpers/string'
import { FILTER_TYPES } from '../../../js/data/filters/config'
// Ensure other child component APIs are loaded on time
require('../../components/choice-list/main')

const widgetApi = 'w-choice-list-filter'
const widgetQueries = {
  choiceList: `[data-${widgetApi}__choice-list]`
}

export default class ChoiceListFilter {
  /**
   * Creates a new ChoiceListFilter
   *
   * @constructor
   *
   * @param {HTMLElement} element - The element where to attach ChoiceListFilter
   * @param {FilterModel} filterModel - The associated FilterModel
   */
  constructor (element, filterModel) {
    this.element = element
    this.settings = { ...getData(this.element) }
    this.choiceListElement = this.element.querySelector(widgetQueries.choiceList)
    this.choiceListName = this.choiceListElement.id
    this.choiceListApi = this.choiceListElement['c-choice-list']

    this.isDynamic = this.settings.isDynamic

    this.setFilterModel(filterModel)

    // Bind choice list events to filterModel
    this.choiceListApi.events.on('changeOptions', () => {
      this.setChoiceListOptionsToFilter()
    })
  }

  /**
   * Set the current filterModel
   * - Set given filterModel to filterModel self property
   * - Set options on choice list
   * - Bind filter model events
   *
   * @param {FilterModel} filterModel - The associated FilterModel
   *
   * @returns {ChoiceListFilter} self instance
   */
  setFilterModel (filterModel) {
    this.filterModel = filterModel
    // Setting options silently so that value prop changed is not triggered in case it changes when changing options
    const setChoiceListOptions = () => {
      if (this.isDynamic) {
        this.choiceListApi.setProp('method', this.filterModel.getAttribute('isMultiselectable') ? 'multiple' : 'single', { silent: true })
      }
      this.choiceListApi.setProp('options', this.getChoiceListOptionsFromFilter(), { silent: true, isDynamic: this.isDynamic, forceUpdate: this.isDynamic })
    }
    setChoiceListOptions()

    this.filterModel.events.on('change', () => {
      setChoiceListOptions()
    })

    return this
  }

  /**
   * Set the current Choice List selectedValues to filter
   *
   * @returns {ChoiceListFilter} self instance
   */
  setChoiceListOptionsToFilter () {
    this.filterModel
      .clearSelection({ silent: true })
      .setSelectedValues(this.choiceListApi.getSelectedValues())
    return this
  }

  /**
   * Get the current ChoiceList options from filter
   *
   * @returns {defaultCheckboxData[] | defaultRadioButtonData[]} The parsed filter to ChoiceList options
   */
  getChoiceListOptionsFromFilter () {
    // Adding the default option as selected, in case no filterModel value is selected
    const groups = [...new Set(this.filterModel.values.models.map(model => model.getAttribute('groupInfo')))]
    if (!groups.some(value => value === null || value === undefined || value === '')) {
      return groups.map(group => ({
        id: group,
        name: this.choiceListName,
        value: group,
        text: capitalizeString(group),
        checked: false,
        disabled: false,
        items: this.filterModel.values.models.filter(model => model.getAttribute('groupInfo') === group).map(model => this._mapModel(model))
      }))
    }
    return this.filterModel.values.models.map(model => this._mapModel(model))
  }

  _mapModel (model) {
    return {
      id: model.getAttribute('value'),
      name: this.choiceListName,
      value: model.getAttribute('value'),
      text: this._generateOptionText(this.filterModel.getAttribute('type'), model),
      count: model.getAttribute('count'),
      checked: model.getAttribute('isSelected'),
      disabled: !model.getAttribute('isAvailable')
    }
  }

  _generateOptionText (type, model) {
    return type === FILTER_TYPES.STARS
      ? model.getAttribute('caption') === ''
        ? RatingTemplate({ value: model.getAttribute('value'), size: 'l' })
        : `${model.getAttribute('caption')} ${RatingTemplate({ value: model.getAttribute('value'), size: 'l' })}`
      : model.getAttribute('caption')
  }
}
