export function adaptPriceRangeChangePayload (configuration, priceRange) {
  const { maxPrice, step } = configuration
  const [lowestPrice, highestPrice] = priceRange

  const isOpenRange = highestPrice === null
  const ensuredHighestPrice = isOpenRange ? maxPrice : highestPrice
  const elementsInRange = ((ensuredHighestPrice - lowestPrice) / step) + 1

  const range = Array(elementsInRange)
    .fill(lowestPrice)
    .map((price, i) => String(price + i * step))

  if (isOpenRange) {
    return [...range, '999999']
  }

  return range
}
